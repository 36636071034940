import React, { useCallback, useContext } from "react"
import { Text, TouchableOpacity, View } from "react-native"
import useStyles from "./styles.css";
import { mobileSize } from "@src/utils/screensize-helper";
import Icon from "@expo/vector-icons/MaterialIcons";

import { DrawerContext } from "@src/screens/drawer-screens/drawer-provider";
import colors from "@assets/colors";
import SVGIcon from "@assets/svg";

import Button from "@src/components/button/Button01";
import { useFetchDelete } from "@src/utils/api/hooks";
import { useRoute } from "@react-navigation/native";
import _ from "lodash";
import { useAuthService } from "@src/ducks/hooks";

const DeleteAccount = () => {

    const route = useRoute<any>();
    const token = route?.params?.token ?? "";

    const { onLogout } = useAuthService();

    const styles = useStyles();
    const isMobile = mobileSize();
    const { onActiveTab } = useContext(DrawerContext);

    const {loading, data, error, runRequest } = useFetchDelete();

    const onDelete = useCallback(() => {
        runRequest(`/user/me?token=${token}`);
    }, [])

    React.useEffect(() => {
        if(!_.isEmpty(data)){
            onLogout()
        }
    })

    return (
        <View style={styles.container}>
            <View style={isMobile ? styles.header_title_background : styles.header_title_top}>
                <TouchableOpacity activeOpacity={0.8} style={styles.v_invite_link_wrapper} onPress={() => onActiveTab("My Account")}>
                    <Icon name="arrow-back" size={isMobile ? 12 : 23} color={isMobile ? colors.black : colors.grayPrimary} />
                    <Text numberOfLines={1} style={styles.header_title_top}>Back to My Account</Text>
                </TouchableOpacity>
            </View>
            <View style={[styles.signInCenter]}>
                <View style={[styles.divider, {marginTop:224}]} />
                <View style={styles.v_account}>
                    <SVGIcon name="Delete" width={102} height={115} fill={colors.colorPrimary}/>
                    <Text style={styles.t_init_message}>Please note that starting the account deletion process is a non reversible action</Text>
                    <Text style={styles.t_init_message}>Are you certain you want to delete your account?</Text>

                    <View style={{flexDirection: "row", alignItems:"center"}}>
                        <Button 
                            label="Cancel"
                            onPress={() => onActiveTab("My Account")}
                            style={styles.btn_cancel}
                            labelStyle={styles.label_cancel}
                        />
                        <View style={styles.midDivider} />
                        <Button 
                            label="Delete Account"
                            style={styles.btn_proceed}
                            labelStyle={styles.label_proceed}
                            onPress={onDelete}
                        />
                    </View>
                </View>
            </View>
        </View>
    )
}

export default DeleteAccount