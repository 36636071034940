import colors from "@assets/colors";
import images from "@assets/images";
import { Button01 } from "@src/components/button";
import useOrientationHelper from "@src/utils/orientation-helper";
import { tabletSize } from "@src/utils/screensize-helper";
import React from "react";
import { Image, Text, View } from "react-native";
import { SignInContext } from "../provider";
import FaceRecognition from "./camera";
import ProgressBar from "./progress-bar";
import PulseAnimation from "./pulse";
import useStyles from "./styles.css";

const Mask = () => {
  const styles = useStyles();
  const isTablet = tabletSize();
  const isLandScape = useOrientationHelper();
  const { 
    enableScanning, scanFailed, enableCamera, handleScanning, handleScanComplete, 
    handleScanFailed, handleProgressValue , 
    scanSuccess, handleEnableCamera 
  } = React.useContext(SignInContext);

  return (
    <View style={[styles.v_mask_container, enableCamera && { backgroundColor: colors.transparent }]}>
      {(enableCamera && !isLandScape) && <FaceRecognition
        isLandScape={isLandScape}
        onScanning={() => handleScanning(true)}
        onScanFailed={handleScanFailed}
        onScanCompleted={handleScanComplete}
        onProgress ={(value : number) => handleProgressValue(value)}
      />}
      <Text style={[styles.t_face_recog, 
        (enableCamera && isTablet) && { color: colors.white }]}>
        {scanFailed ? "Face Not Recognized" : "Face Recognition"}
      </Text>
      <Text style={[styles.t_fr_sub, (enableCamera && isTablet) && { color: colors.white }]}>
          Blink to scan your face and verify your identity
      </Text> 
      <View style={[styles.v_frame_center]}>
        {(enableCamera && isLandScape) && <FaceRecognition
          isLandScape={isLandScape}
          onScanning={() => handleScanning(true)}
          onScanFailed={handleScanFailed}
          onScanCompleted={handleScanComplete}
          onProgress ={(value : number) => handleProgressValue(value)}
        />}
        <Image
          style={[styles.i_frame, isLandScape && styles.i_frame_landscape]}
          source={images.scan_frame}
          resizeMode="stretch"
        />
        <PulseAnimation />
      </View>
      {enableScanning && !scanFailed && <ProgressBar />}
      {(!enableCamera && !scanSuccess) && <Button01
        style={[styles.btn_style, isLandScape && styles.btn_style_lanscape]}
        label={scanFailed ? "Try Again" : "Get Started"}
        onPress={() => handleEnableCamera(true)}
      />}
    </View>
  );
};

export default Mask;
