import React, { useRef, useCallback } from "react";
import { View, FlatList, Pressable, useWindowDimensions } from "react-native";
import useStyles, { ITEM_HEIGHT } from "./styles.css";
import MenuItem from "./menu-item";

export interface MeasureValue {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface MenuDropdownProps {
  visible: boolean;
  measures: MeasureValue;
  value: any;
  options: any;
  onClose: () => void;
  renderItem?: any;
  onSelected: (item: any) => void;
}

const PopupMenu = (props: MenuDropdownProps) => {
  const styles = useStyles();
  const flatlist = useRef(null);
  const { height: HEIGHT } = useWindowDimensions();
  
  const stylePosition = React.useMemo(() => {
    const { x, y, width, height } = props.measures;
    const base_height =
    props.options.length > 6
      ? ITEM_HEIGHT * 6
      : props.options.length * ITEM_HEIGHT;
    const pos_upward = y + base_height;
    const isGreaterThanHeight = pos_upward > HEIGHT;
    const positionStyle = {
      height: base_height,
      width: width,
      left: x,
      top: isGreaterThanHeight ? y - base_height :  y + height,
    };

    return positionStyle;
  }, [props.measures, props.options, HEIGHT]);

  const RenderItem = useCallback(
    ({ item, index }: any) => {
      if(typeof props.renderItem === "function"){
        return props.renderItem({ 
          item, index, 
          onSelected: () => {
            props.onSelected(item);
            props.onClose();
          },   
        });
      }

      return (
        <MenuItem 
          key={`${index}`}
          item={item}
          value={props.value}
          onPress={() => {
            props.onSelected(item);
            props.onClose();
          }}/>
      );
    },
    [props.value, props.onSelected]
  );

  if(!props.visible) return null;

  return (
    <Pressable style={styles.modal} onPress={props.onClose}>
      <View style={[styles.dropdown, stylePosition]}>
        <FlatList
          ref={flatlist}
          data={props.options}
          getItemLayout={(item, index) => ({
            length: ITEM_HEIGHT,
            offset: ITEM_HEIGHT * index,
            index,
          })}
          keyExtractor={(_, index) => `${index}`}
          renderItem={RenderItem}
        />
      </View>
    </Pressable>
  );
};

export default React.memo(PopupMenu);
