import React from "react";
import { DrawerIconProps } from "@src/components/icon/interface";
import Dashboard from "./dashboard.svg";
import Document from "./document.svg";
import Inbox from "./inbox.svg";
import Logout from "./logout.svg";
import Settings from "./settings.svg";
import ManageSubs from "./ManageSubs.svg"
import ManagePromo from "./managePromo.svg"
import colors from "@assets/colors";

const DrawerIcon = (props: DrawerIconProps) => {
  const { name, isActive, width, height } = props;
  const fill = isActive ? colors.gray12 : colors.white;

  switch (name) {
  case "Dashboard":
    return <Dashboard fill={fill} width={width || 22} height={height || 20} />;
  case "Subscribers":
    return <ManageSubs fill={fill} width={width || 25} height={height || 22} />;
  case "Logout":
    return <Logout fill={fill} width={width || 20} height={height || 20.94} />;
  case "Settings":
    return <Settings fill={fill} width={width || 24} height={height || 24} />;
  case "Promo":
    return <ManagePromo fill={fill} width={width || 25} height={height || 25} />;
  }
};

export default DrawerIcon;
