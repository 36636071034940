import TypographyStyle from "@assets/typography";
import { StyleSheet } from "react-native";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { colors } from "theme";

const useStyles = CreateResponsiveStyle(
  {
    container: { flex: 1 },
    containerPortrait: { alignItems: "center", justifyContent: "center" },
    v_body: { flex: 1, flexDirection: "row", zIndex: 3 },
    v_body_portrait: {
      width: "100%",
      alignItems: "flex-start",
      marginHorizontal: 20,
    },
    v_forgot: {
      width: "50%",
      alignItems: "center",
      paddingHorizontal: "8%",
      justifyContent: "center",
      backgroundColor: colors.white,
    },
    v_forgot_landscape: {
      width: "50%",
      alignItems: "center",
      justifyContent: "center",
      paddingHorizontal: "9%",
      backgroundColor: colors.white,
    },
    signInCenter: { alignItems: "center" },
    i_fp_success: { width: 110, height: 110 },
    txtNote1: {
      fontFamily: "DMSans-Bold",
      fontSize: 40,
      lineHeight: 52,
      textAlign: "center",
      color: colors.blue,
    },
    txtNote2: {
      fontFamily: "DMSans",
      fontSize: 16,
      lineHeight: 24,
      marginTop: 31,
      textAlign: "center",
      color: colors.black3,
    },
    v_field: { marginTop: 20, width: "100%" },
    labelStyle: { fontFamily: "DMSans-Medium" },
    errorColor:{
      color: "red",
      marginTop:10
    },
    expiredOTP:{
      color: "red",
      marginTop:10,
      paddingBottom:20,
      textAlign:"center"
    },
    
    t_signup: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        marginTop: 30,
        textAlign: "center",
        color: colors.black3,
      },
    ]),
    t_signup_2: {
      color: colors.blue,
      fontWeight: "700",
      marginLeft: 7
    },
    termsStyle: {position: "relative", marginTop: 20, paddingBottom: 50},
    btn_signin: {
      width: "100%",
      marginTop: 50,
    },
    lbl_signin: { fontFamily: "Montserrat-Bold" },
    
    // TIMER
    error_container: { minHeight: 88, alignItems: "center", justifyContent: "center"},
    t_error: { 
      fontFamily: "DMSans",
      color: colors.red, 
      fontSize: 16, lineHeight: 24,
      textAlign: "center" },

    // BACKGROUND
    linearStyle: {
      position: "absolute",
      zIndex: -1,
      width: "100%",
      height: "100%",
    },

    // VERIFICATION
    cell: {
      width: 84,
      height: 76,
      marginHorizontal: 10,
      fontSize: 36,
      lineHeight: 65,
      borderRadius: 5,
      borderWidth: 1,
      textAlign: "center",
      borderColor: colors.border7,
    },
    focusCell: { borderColor: colors.black3 },
    errorCell: { borderColor: colors.red },

    v_height_top: { height: "10%" },
    v_privacy: { display: "none", backgroundColor: "white" },
  },
  {
    [DEVICE_SIZES.LG]: {
      v_forgot_landscape: {
        paddingHorizontal: "7%",
      },
      v_height_top: {height: 145},
      txtNote1: { fontSize: 37.5, lineHeight: 39 },
      txtNote2: { fontSize: 12, lineHeight: 18, marginTop: 14 },
      t_signup: { fontSize: 10.5, lineHeight: 10.5 },
      lbl_signin: { fontSize: 10.5, lineHeight: 12 },
      i_fp_success: { width: 82.5, height: 82.5 },
    },
    [DEVICE_SIZES.MD]: {
      v_height_top: { height: 51 },
      v_body_portrait: {
        width: "100%",
        alignItems: "center",
        marginHorizontal: 20,
        height: "80%"
      },
      v_forgot: {
        width: "100%",
        paddingTop: 100,
        alignItems: "center",
        paddingHorizontal: "20%",
        justifyContent: "center",
        backgroundColor: colors.white,
      },
      v_forgot_landscape: {
        width: "50%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: "10%",
        backgroundColor: colors.white,
      },
      txtNote1: { fontSize: 37.5, lineHeight: 39 },
      txtNote2: { fontSize: 12, lineHeight: 18, marginTop: 14 },
      t_signup: { fontSize: 10.5, lineHeight: 10.5 },
      lbl_signin: { fontSize: 10.5, lineHeight: 12 },
      i_fp_success: { width: 82.5, height: 82.5 },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      v_height_top: { height: 51 },
      v_forgot: {
        paddingTop: 50,
        minWidth: "100%",
        maxWidth: "100%",
        paddingHorizontal: 20,
      },
      v_forgot_landscape: {
        width: "50%",
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: "10%",
        backgroundColor: colors.white,
      },
      v_body_portrait: {
        width: "100%",
        alignItems: "center",
        marginHorizontal: 20,
        height: "80%"
      },
      v_field: { marginTop: 10 },
      t_signup: { fontSize: 12, lineHeight: 14 },
      t_error: { fontSize: 13, lineHeight: 20 },
      txtNote1: { fontSize: 22, lineHeight: 25 },
      txtNote2: {
        marginTop: 10,
        fontSize: 12,
        lineHeight: 18,
      },
      v_privacy: { display: "flex" },
      cell: {
        width: 65,
        height: 62,
        marginHorizontal: 10,
        fontSize: 31,
        lineHeight: 60,
        borderRadius: 5,
        borderWidth: 1,
        textAlign: "center",
        borderColor: colors.border7,
      },
    },
  }
);

export default useStyles;
