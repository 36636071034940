import React from "react";
import { useNavigation } from "@react-navigation/native";
import { View, Text, ScrollView } from "react-native";
import { Button01 } from "@src/components/button";
import useStyles from "./styles.css";
import Header from "./header";

const TermsCondition = () => {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <Header />
      <View style={styles.v_body}>
        <ScrollView>
          <Text style={styles.t_title_terms}>Terms and Conditions</Text>
          <Text style={[styles.t_note, { marginTop: 10 }]}>
            The Terms and Conditions (the “<span style={styles.strong}>Terms</span>") on this page provide for the conditions, restrictions, and terms under which you can use the website (the “<span style={styles.strong}>Website”</span>) and the services or products provided by UNAWA. By availing of any services or products from UNAWA, or by using the Website, you indicate that you consent to and accept these Terms and agree to be bound by and abide by them.
          </Text>

          <Text style={styles.t_title}>1. UNAWA</Text>
          <Text style={[styles.t_note, { marginTop: 10 }]}>
            UNAWA Inc. (“<span style={styles.strong}>UNAWA</span>") is an entity duly organized under the laws of the Philippines and registered with the Securities and Exchange Commission, with business address at 10F 8 Rockwell, Hidalgo corner Plaza Drives, Rockwell Center, Makati City, Philippines.
          </Text>

          <Text style={styles.t_title}>2. Use of Website</Text>
          <Text style={[styles.t_note, { marginTop: 10 }]}>
            2.1 Persons visiting the Website or availing of services or products through it (the “Users”) understand that the Website may be used for the following purposes:
            {'\n'}
            {'\n'}
            <View style={styles.indent}>
              <Text>1. To obtain more information about UNAWA, the company, its services or products, its activities, its social media presence, its news and updates, its affiliates, and other related information;</Text>
              <Text>2. To avail of its services or products, which may require Users to submit data and documents to facilitate the service and make payments to UNAWA through payment gateways;</Text>
              <Text>3. To contact UNAWA with queries, concerns, clarifications, which may require Users to submit their personal information and contact details; and</Text>
              <Text>4. Any other related or analogous purpose.</Text>
            </View>
            {'\n'}
            {'\n'}
            2.2 Users shall not use the website in any manner that is contrary to any law, statute, regulation, or issuance of a government authority of the Philippines, or of the jurisdiction where the User is located, including those pertaining to intellectual property or cybersecurity.
            {'\n'}
            {'\n'}
            2.3 Users understand that their use of the Website and UNAWA services and products is through their own software, devices, and network. UNAWA does not warrant the compatibility of the Website or its services and products and the computer equipment, devices, software, or platform through which they are accessed by Users. Thus, UNAWA shall not be liable for any damage that may arise from any incompatibility or loss of functionality. UNAWA likewise shall not be liable for any damage to such equipment, devices, software, or platform that results from your access to or use of the Website or UNAWA services and products.
            {'\n'}
            {'\n'}
            2.4 The Website may link to external websites, including social media platforms on which UNAWA maintains official accounts and websites of UNAWA affiliates. UNAWA shall not be responsible for content on external websites that it has not created, generated, or consented to being associated with.
          </Text>

          <Text style={styles.t_title}>3. Availment of Services or Products</Text>
          <Text style={[styles.t_note, { marginTop: 10 }]}>
            3.1 Users understand that UNAWA is not engaged in the practice of law. Users likewise understand that UNAWA does not furnish binding legal advice through the provision of its services and products, and no lawyer-client relationship is established between UNAWA and the Users.
            {'\n'}
            {'\n'}
            3.2 The time periods indicated in this Website with respect to the provision of services may be affected by the following:
            {'\n'}
            {'\n'}
            <View style={styles.indent}>
              <Text>1. Processes of relevant government authorities;</Text>
              <Text>2. Deficiencies in documents or submissions; or</Text>
              <Text>3. Force majeure or any other unforeseeable and unavoidable causes unattributable to UNAWA.</Text>
            </View>
            {'\n'}
            {'\n'}
            Such periods must therefore be taken as indicative. Should the processing for transactions exceed these indicative periods, it cannot be taken as a breach upon which UNAWA may be made liable.
            {'\n'}
            {'\n'}
            3.3 In availing of UNAWA services or products, we note that Users may be required to submit data or provide documents that contain personal information as defined under the Data Privacy Act of 2012, its Implementing Rules and Regulations, issuances of the National Privacy Commission, and other relevant data protection laws and issuances (the “<span style={styles.strong}>Philippine Privacy Laws</span>”). For more information on how UNAWA processes Users’ personal information, please refer to our Privacy Policy.
          </Text>

          <Text style={styles.t_title}>4. Obligations of UNAWA</Text>
          <Text style={[styles.t_note, { marginTop: 10 }]}>
            In providing its services and products, UNAWA shall:
            {'\n'}
            {'\n'}
            <View style={styles.indent}>
              <Text>1. Observe all obligations under Philippine Privacy Laws in the processing of personal information from Users. For more information on how UNAWA processes Users’ personal information, please refer to our Privacy Policy.</Text>
              <Text>2. Comply with requirements of cybersecurity under relevant Philippine laws and under globally acceptable standards.</Text>
              <Text>3. Maintain reasonable provisions for the scalability, security, and resilience of relevant software and platforms.</Text>
            </View>
          </Text>

          <Text style={styles.t_title}>5. General Provisions</Text>
          <Text style={[styles.t_note, { marginTop: 10 }]}>
            5.1 UNAWA reserves the right to amend these Terms at any time, without prior notice to Users, but with due subsequent notice. Continued use of the Website or of UNAWA services or products after amendment shall constitute acceptance of the new Terms.
            {'\n'}
            {'\n'}
            5.2 UNAWA shall retain ownership of all its intellectual property, and no use thereof may be carried out without UNAWA’s prior consent and approval in writing. In turn, Users shall retain ownership over intellectual property that may be processed through UNAWA, such as logos, trade names, marks, or other intellectual property.
            {'\n'}
            {'\n'}
            5.3 UNAWA’s failure or delay in enforcing any right under these Terms shall not be construed as a waiver or limitation of its right to subsequently enforce these Terms and compel strict compliance therewith.
            {'\n'}
            {'\n'}
            5.4 Should any part of these Terms be declared as invalid, unenforceable, or in conflict with the applicable laws or regulations of any jurisdiction, such invalidity, unenforceability, or conflict shall in no way affect the rest of the Terms, which shall remain in full force and effect and binding upon UNAWA and the User.
            {'\n'}
            {'\n'}
            5.5 Nothing in these Terms shall create or be deemed to create a partnership, agency, or employer-employee relationship between UNAWA and any User.
            {'\n'}
            {'\n'}
            5.6 These Terms, as well as any obligations that may arise from them, shall be governed and construed in accordance with Philippine laws. Any dispute that may result from these terms shall be submitted to the exclusive jurisdiction of the courts of Makati City, Metro Manila, Philippines.
          </Text>
        </ScrollView>
      </View>
      <View style={{ height: 50 }} />
    </View>
  );
};

export default TermsCondition;
