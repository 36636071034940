import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../ducksHook";
import {
  forgotActions,
  selectForgotLoad,
  selectForgotFailed,
  selectForgotSuccess,
  selectResetSuccess,
} from "../slices/forgot.slice";

// Types
import { ErrorValue, LoginInput, ResetPasswordInput } from "../types";

type ServiceOperators = {
  failed: ErrorValue;
  isLoading: boolean;
  resetSuccess: boolean;
  forgotSuccess: boolean;
  onForgot: (params: LoginInput) => void;
  onReset: (params: ResetPasswordInput) => void;
};

export const useForgotService = (): Readonly<ServiceOperators> => {
  const dispatch = useAppDispatch();

  return {
    failed: useAppSelector(selectForgotFailed),
    isLoading: useAppSelector(selectForgotLoad),
    forgotSuccess: useAppSelector(selectForgotSuccess),
    resetSuccess: useAppSelector(selectResetSuccess),
    onForgot: useCallback(
      (params: LoginInput) => {
        dispatch(forgotActions.forgotRequest(params));
      },
      [dispatch]
    ),
    onReset: useCallback(
      (params: ResetPasswordInput) => {
        dispatch(forgotActions.resetRequest(params));
      },
      [dispatch]
    ),
  };
};

export default useForgotService;
