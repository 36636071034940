import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../ducksHook";
import {
    settingsActions,
    selectMaintenance,
    selectSettingsLoading,
    selectSettingsFailed,
    selectSettingsUpdatedAt
} from "../slices/settings.slice";

// Types
import { ErrorValue, AppConfig } from "../types";

type ServiceOperators = {
  updatedAt?: Date;
  failed: ErrorValue;
  isLoading: boolean;
  maintenance: boolean;
  onUpdateMaintenance: (params: AppConfig) => void;
};

export const useSettingsService = (): Readonly<ServiceOperators> => {
  const dispatch = useAppDispatch();

  return {
    updatedAt: useAppSelector(selectSettingsUpdatedAt),
    failed: useAppSelector(selectSettingsFailed),
    isLoading: useAppSelector(selectSettingsLoading),
    maintenance: useAppSelector(selectMaintenance),
    onUpdateMaintenance: useCallback(
      (params: AppConfig) => {
        dispatch(settingsActions.updateMaintenanceRequest(params));
      },
      [dispatch]
    ),
  };
};

export default useSettingsService;
