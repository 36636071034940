import React from "react";
import {
  View,
  Text,
  Image,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import TypographyStyle from "@assets/typography";
import { Button01Types } from "./types";
import { colors } from "@assets/index";

const Button = (props: Button01Types) => {
  const backgroundColor = props.disabled && {
    backgroundColor: colors.border2,
  };

  return (
    <View style={[styles.container, props.style, backgroundColor]}>
      <TouchableOpacity
        activeOpacity={0.8}
        style={styles.buttonStyle}
        onPress={props.onPress}
        disabled={props.disabled || props.isLoading}
      >
        {props.isLoading ? (
          <ActivityIndicator
            animating
            size="small"
            color={props.color || "white"}
          />
        ) : (
          <>
            {props.source && (
              <Image
                source={props.source}
                resizeMode="contain"
                style={[styles.imgStyle, props.imgStyle]}
              />
            )}
            <Text style={[styles.labelStyle, props.labelStyle]}>
              {props.label || "Next"}
            </Text>
          </>
        )}
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 48,
    borderRadius: 8,
    paddingHorizontal: 20,
    backgroundColor: colors.blue,
  },
  buttonStyle: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  imgStyle: { height: 23, width: 23, marginRight: 18 },
  labelStyle: TypographyStyle.buttonText,
});

export default React.memo(Button);
