import { useNavigation } from "@react-navigation/native";
import { useAuthService } from "@src/ducks/hooks";
import React from "react";
import { Text, View } from "react-native";
import useStyles from "../styles.css";

const Error = React.forwardRef((_, ref: any) => {
  const styles = useStyles();
  const { failed } = useAuthService();
  const navigation = useNavigation<any>();
  const [message, setMessage] = React.useState("");

  React.useImperativeHandle(ref, () => ({
    onClear: () => setMessage(""),
  }));

  React.useEffect(() => {
    if (failed.message) {
      if(failed.status && failed.status === 403){
        navigation.navigate("signup-resend-verification");
        return;
      }
      
      const failedMessage = failed.message.replace(/^HttpException:\s*/, "");
      setMessage(failedMessage);
    }
  }, [failed.message]);

  if (!message) {
    return <View style={{ height: 20}} />;
  }

  return <Text style={styles.t_error}>{message}</Text>;
});

export default React.memo(Error);
