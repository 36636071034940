import images from "@assets/images";
import { useNavigation } from "@react-navigation/native";
import { Button01 } from "@src/components/button";
import useOrientationHelper from "@src/utils/orientation-helper";
import { tabletSize } from "@src/utils/screensize-helper";
import React from "react";
import { Image, Text, View } from "react-native";
import Background from "../signin/background";
import Logo from "../signin/logo";
import LogoTop from "../signin/logo-top";
import useStyles from "./styles.css";

const VerificationFailed = () => {
  const styles = useStyles();
  const isTablet = tabletSize();
  const navigation = useNavigation<any>();
  const isLandScape = useOrientationHelper();
  const title = "Account verification failed";
  const notes =
    "This email is already verified. Please double check the email you are trying to verify.";
  const btnLabel = "Back to log in";

  const onPress = () => {
    navigation.navigate("Signin");
  };

  return (
    <View style={isLandScape ? styles.container : styles.containerPortrait}>
      <Background />
      <View style={isLandScape ? styles.v_body : styles.v_body_portrait}>
        {isTablet ? <LogoTop /> : <Logo /> }
        <View style={isLandScape ? styles.v_signup_landscape : styles.v_signup }>
          <Image
            style={styles.i_style}
            source={images.error}
            resizeMode="stretch"
          />
          <Text style={styles.t_title}>{title}</Text>
          <Text style={[styles.t_note, { marginTop: 10 }]}>{notes}</Text>
          <Button01
            onPress={onPress}
            style={styles.btn_dashboard}
            label={btnLabel}
            labelStyle={styles.btn_label}
          />
        </View>
      </View>
    </View>
  );
};

export default VerificationFailed;
