import { SagaIterator } from "@redux-saga/core";
import { call, delay, put, race, select, take, takeEvery } from "redux-saga/effects";

// Types
import { WEB_BASE_URL } from "@env";
import * as Types from "../types";

// API
import {
  createMayaCustomer,
  forgotpassword,
  generateMayaToken,
  linkGeneratedMayaTokenToCustomer,
  login,
  loginFaceID,
  paymayaTransaction,
  refreshToken,
  register,
  resetpassword,
  verifyPromoCode,
} from "@src/utils/api";

// Slice
import { authActions, selectAuthLoginInput, selectAuthSession } from "../slices/auth.slice";
import { dashboardActions } from "../slices/dashboard.slice";
import { forgotActions } from "../slices/forgot.slice";
import { signsecureActions } from "../slices/signsecure.slice";
import { signupActions } from "../slices/signup.slice";
import { userActions } from "../slices/user.slice";
// import moment from "moment";
import { calculatePromoDiscount } from "@src/utils/filter-helper";
import moment from "@src/utils/moment-timezone-helper";

function* handleSignin(action: {
  type: typeof authActions.loginRequest;
  payload: Types.LoginInput;
}): SagaIterator {
  try {

    const user = yield call(login, action.payload);
    // Check if the user role is either super-admin or admin
    const userRole = user.data.role; // Assuming user.data contains the role information
    if (userRole === 'super-admin') {
      yield put(authActions.loginSuccess({...user.data, loginType: "Login via Email"}));
      gtag("event", "login", { "method": "Login via Email" });
    } else {
      // Dispatch a failure action if the user is not authorized

      yield put(authActions.loginFailure({ message: "Unauthorized: You do not have access.", status: 403 }));
    }

  } catch (error: any) {    
    const errorMessage = "Unauthorized: You do not have access.";
    yield put(authActions.loginFailure({ errorMessage })); // Dispatch login failure

  }

}

function* handleFaceIDSignin(): SagaIterator {
  try {
    const { email } = yield select(selectAuthLoginInput); 
    const user = yield call(loginFaceID, {email});
    const param ={ 
      id: user.data?.id,
      role: user.data?.role,
      userId: user.data?.id,
      email: user.data?.email,
      givenName: user.data?.givenName,
      lastName: user.data?.lastName,
      accessToken: user.data?.accessToken,
      refreshToken: user.data?.refreshToken,
      loginType: "Login via Face Recognition"
    };
    yield put(authActions.loginSuccess(param));
    gtag("event", "login", { "method": "Login via Face Recognition" });
  } catch (error: any) {
    console.log("error", error, typeof error.error);
    
    const message = typeof error.error === "object" ? error.error[0] : error.message || error.error || "Somthing went wrong";
    const failedMessage = message?.replace(/^(HttpException:|NotFoundException:)\s*/, "");

    yield put(authActions.loginFailure({ message: failedMessage }));
    yield delay(1000);
    // yield put(authActions.loginFailure({}));
  }
}

function* handleSignup(action: {
  type: typeof signupActions.signupRequest;
  payload: Types.SignupInput;
}): SagaIterator {
  try {
    const user = yield call(register, action.payload);

    yield put(signupActions.signupSuccess(user.data));
    yield delay(1000);
    yield put(signupActions.signupSuccess({}));
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    const failedMessage = message?.replace(/^(HttpException:|NotFoundException:)\s*/, "");

    yield put(signupActions.signupFailure({ message: failedMessage }));
    yield delay(1000);
    yield put(signupActions.signupFailure({}));
  }
}

function* handleSignupWithPromo(action: {
  type: typeof signupActions.signupRequest;
  payload: Types.SignupWithPromoValue;
}): SagaIterator {
  try {
    let cost = action.payload.cost;

    if(action.payload.promoCode){
      const promoDiscount = yield call(verifyPromoCode, {
        code: action.payload.promoCode,
        email: action.payload.email,
      });
      cost = yield call(calculatePromoDiscount, 
        action.payload.cost, promoDiscount.data || {});
    }

    const customerParams = {
      "contact": {
        "phone": "",
        "email": action.payload.email
      },
      "firstName": action.payload.givenName,
      "middleName": "",
      "lastName": action.payload.lastName,
      "customerSince": moment().format("YYYY-MM-DD"),
    };
    const customer = yield call(createMayaCustomer, customerParams);
    const token = yield call(generateMayaToken, {card: action.payload.card });
    const cardToken = yield call(linkGeneratedMayaTokenToCustomer, {
      customerId: customer.id,
      isDefault: true,
      paymentTokenId: token.paymentTokenId
    });
    const transaction = yield call(paymayaTransaction, customer.id, cardToken.cardTokenId, {
      totalAmount: {
        amount: cost,
        currency: "PHP"
      },
      redirectUrl: {
        success: `${WEB_BASE_URL}/payment-success`,
        failure: `${WEB_BASE_URL}/payment-failed`,
      },
      requestReferenceNumber: action.payload.id,
      metadata: {
        pf: {
          smi: "",
          smn: `${action.payload.givenName} ${action.payload.lastName}`,
          mpc: "PHP",
          mco: "PHL",
        },
        user: {
          email: action.payload.email,
          firstName: action.payload.givenName,
          lastName: action.payload.lastName,
          password: action.payload.password,
          promoCode: action.payload.promoCode,
          mayaCustomerId: customer.id,
          mayaCardtokenId: cardToken.cardTokenId,
        },
        subMerchantRequestReferenceNumber: "",
      },
    });

    window.location.href = transaction.verificationUrl;
    yield put(signupActions.signupSuccess({}));
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    const failedMessage = message?.replace(/^(HttpException:|NotFoundException:)\s*/, "");

    yield put(signupActions.signupFailure({ message: failedMessage }));
    yield delay(1000);
    yield put(signupActions.signupFailure({}));
  }
}

function* handleForgotpassword(action: {
  type: typeof forgotActions.forgotRequest;
  payload: Types.LoginInput;
}): SagaIterator {
  try {
    const user = yield call(forgotpassword, action.payload);

    yield put(forgotActions.forgotSuccess(user.data));
  } catch (error: any) {
    const message = error.message || error.error || "Somthing went wrong";
    const failedMessage = message?.replace(/^(HttpException:|NotFoundException:)\s*/, "");

    yield put(forgotActions.forgotFailed({ message: failedMessage }));
    yield delay(1000);
    yield put(forgotActions.forgotFailed({}));
  }
}

function* handleResetpassword(action: {
  type: typeof forgotActions.forgotRequest;
  payload: Types.ResetPasswordInput;
}): SagaIterator {
  try {
    const user = yield call(resetpassword, action.payload);

    yield put(forgotActions.resetSuccess(user.data));
  } catch (error: any) {
    const message = error.message || error.error || "Somthing went wrong";
    const failedMessage = message?.replace(/^(HttpException:|NotFoundException:)\s*/, "");

    yield put(forgotActions.resetFailed({ message: failedMessage }));
    yield delay(1000);
    yield put(forgotActions.resetFailed({}));
  }
}

function* handleRefreshTokenLogic(): SagaIterator {
  yield race([
    call(startPeriodicRefresh),
    take(authActions.logout.type),  // Wait for the next toggle (stop)
  ]);
}

function* startPeriodicRefresh() {
  try {
    while (true) {
      yield delay(60 * 30 * 1000); // 30 MINUTES
      yield call(refreshTokenWorker);
    }
  } finally {
    console.log("Refresh task canceled");
  }
}

function* refreshTokenWorker(): SagaIterator {
  try {
    // Make an API call to refresh the token
    const session = yield select(selectAuthSession);
    const params = {
      "userId": session.id,
      "accessToken": session.accessToken,
      "refreshToken": session.refreshToken
    };
    const result = yield call(refreshToken, params);

    // Dispatch an action to update the token in the Redux store
    yield put(authActions.refreshTokenUpdate(result.data.accessToken)); // Replace 'UPDATE_TOKEN' with your actual action type
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    
    if (message.includes("Unauthorized") || message.includes("Invalid refreshToken")) {
      yield put(userActions.failed({ message }));
      yield put(authActions.logout());
    }
  }
}

function* handleLoggout(): SagaIterator {
  yield put(dashboardActions.resetDashboard()); // Reset dashboard states
  yield put(signsecureActions.resetSignSecure()); // Reset signsecure states
  yield put(signsecureActions.setRoutes("")); // Reset signsecure states
  yield put(userActions.resetUser()); // Reset users states
}

// Watcher Saga
function* authWatcherSaga(): SagaIterator {
  yield takeEvery(authActions.loginRequest.type, handleSignin);
  yield takeEvery(authActions.faceIDloginRequest.type, handleFaceIDSignin);
  yield takeEvery(signupActions.signupRequest.type, handleSignup);
  yield takeEvery(signupActions.signupPromoRequest.type, handleSignupWithPromo);
  yield takeEvery(forgotActions.forgotRequest.type, handleForgotpassword);
  yield takeEvery(forgotActions.resetRequest.type, handleResetpassword);
  yield takeEvery(authActions.refreshTokenRequest.type, handleRefreshTokenLogic);
  yield takeEvery(authActions.logout.type, handleLoggout);
}

export default authWatcherSaga;
