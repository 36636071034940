import colors from "@assets/colors";
import Icon from "@expo/vector-icons/MaterialIcons";
import { TextInput01 } from "@src/components/textinput";
import React from "react";
import { DrawerContext } from "../../drawer-provider";
import useStyles from "./styles.css";

const SearchHeader = () => {
  const styles = useStyles();
  const { search, activeTab, onSearchStateChange } = React.useContext(DrawerContext);

  if(["Document Sent",].includes(activeTab) || activeTab === "Support"){
    return null;
  }

  return (
    <TextInput01
      value={search}
      placeholder={"Search"}
      inputStyle={styles.inputStyle}
      onChangeText={onSearchStateChange}
      containerStyle={styles.input_search}
      leftIcon={() => <Icon name="search" size={19} color={colors.grayPrimary} />}
    />
  );
};

export default SearchHeader;
