import * as Font from "expo-font";

// fonts preloading
export const fontAssets = [
  {
    "AzoSans-Bold": require("./AzoSans-Bold.ttf"),
  },
  {
    Roboto: require("./Roboto.ttf"),
  },
  {
    "Roboto-Medium": require("./Roboto-Medium.ttf"),
  },
  {
    "Roboto-Bold": require("./Roboto-Bold.ttf"),
  },
  {
    DMSans: require("./DMSans.ttf"),
  },
  {
    "DMSans-Medium": require("./DMSans-Medium.ttf"),
  },
  {
    "DMSans-Bold": require("./DMSans-Bold.ttf"),
  },
  {
    Montserrat: require("./Montserrat.ttf"),
  },
  {
    "Montserrat-Medium": require("./Montserrat-Medium.ttf"),
  },
  {
    "Montserrat-Bold": require("./Montserrat-Bold.ttf"),
  },
  {
    Lato: require("./Lato.ttf"),
  },
  {
    "Lato-Bold": require("./Lato-Bold.ttf"),
  },
  {
    "Lora": require("./Lora.ttf"),
  },
  {
    "PlayfairDisplay": require("./PlayfairDisplay.ttf"),
  },
  {
    "Redressed": require("./Redressed.ttf"),
  },
  {
    "Yellowtail": require("./Yellowtail.ttf"),
  },
].map((x: any) => Font.loadAsync(x));
