import Loading from "@src/components/loading";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedAuthGuestRole } from "@src/ducks/slices/auth.slice";
import React, { useContext } from "react";
import { DrawerContext } from "../../drawer-provider";

// SCREENS
const Dashboardd = React.lazy(() => import("@src/screens/dashboard"));
const Documents = React.lazy(() => import("@src/screens/Manage-Subscribers"));
const Account = React.lazy(() => import("screens/account"))
const Settings = React.lazy(()=> import("screens/settings/advance-settings"));
const FaceRecognition = React.lazy(()=> import("screens/account/face-id-register"));
const PromoCodes  = React.lazy(()=> import("@src/screens/Promo-Codes"));


const DrawerItemBody = () => {
  const { activeTab } = useContext(DrawerContext);

  const renderContent = React.useMemo(() => {
    switch (activeTab) {
    case "Dashboard":
      return <Dashboardd />;
    case "Promo Codes":
      return <PromoCodes />;
    case "My Account":
      return <Account />;
    case "Manage Subscribers":
      return <Documents />;
    case "Face Recognition":
      return <FaceRecognition />;
    case "Settings":
      return <Settings />;
    default:
      return <Dashboardd />;
    }
  }, [activeTab]);

  return (
    <React.Suspense fallback={<Loading />}>{renderContent}</React.Suspense>
  );
};

export default DrawerItemBody;
