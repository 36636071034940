import { useMemo } from "react";
import { largeSize, mobileSize, tabletSize, xLargeSize } from "./screensize-helper";

type Props = {
  "MOBILE"?: any;
  "TABLET"?: any;
  "LARGE"?: any;
  "XLARGE"?: any;
  "DEFAULT"?: any;
}

const useSVGSizeHelper = (config: Props) => {
  const isTablet = tabletSize();
  const isMobile = mobileSize();
  const isLargeSize = largeSize();
  const isXLargeSize = xLargeSize();

  return useMemo(() => {
    const defaultvalue = config["DEFAULT"] || {};

    if(isMobile){
      return config["MOBILE"] || defaultvalue;
    }else if(isTablet){
      return config["TABLET"] || defaultvalue;
    }else if(isLargeSize){
      return config["LARGE"] || defaultvalue;
    }else if(isXLargeSize){
      return config["XLARGE"] || defaultvalue;
    }else{
      return defaultvalue;
    }
  },[config, isLargeSize, isMobile]);
};

export default useSVGSizeHelper;
