import React from "react";
import { Text, Pressable } from "react-native";
import useStyles from "./styles.css";

interface MenuItemProps {
  item: any;
  value: string;
  onPress: () => void;
}

const MenuItem = ({ item, onPress }: MenuItemProps) => {
  const styles = useStyles();
  const [focus, setFocus] = React.useState(false);
  const newRow = typeof item === "string" ? item : item?.email ?? "";

  return (
    <Pressable
      onPress={onPress}
      onHoverIn={() => setFocus(true)}
      onHoverOut={() => setFocus(false)}
      style={[
        styles.v_item,
        focus && styles.v_item_active,
      ]} >
      <Text style={styles.t_item}>{newRow}</Text>
    </Pressable>
  );
};

export default React.memo(MenuItem);
