import { useCallback } from "react";
import { useAppDispatch } from "../ducksHook";
import { userActions } from "../slices/user.slice";
import { UpgradeSubscription } from "../types";

type Operators = {
  fetchUserDetails: () => void;
  fetchUserSubscription: () => void;
  onCancelSubscription: () => void;
  onUpdateUserDetails: (params: any) => void;
  onUpdateSubscription: (params: any) => void;
  onUpgradeSubscription: (params: UpgradeSubscription) => void;
  resetError: () => void;
};

export const useUserService = (): Readonly<Operators> => {
  const dispatch = useAppDispatch();

  return {
    fetchUserDetails: useCallback(() => {
      dispatch(userActions.fetchUserDetails());
    }, [dispatch]),
    fetchUserSubscription: useCallback(() => {
      dispatch(userActions.fetchUserSubscription());
    }, [dispatch]),
    onCancelSubscription: useCallback(() => {
      dispatch(userActions.cancelSubscription());
    }, [dispatch]),
    onUpdateUserDetails: useCallback((params: any) => {
      dispatch(userActions.updateUserSuccess(params));
    }, [dispatch]),
    onUpdateSubscription: useCallback((params) => {
      dispatch(userActions.subscription(params));
    }, [dispatch]),
    onUpgradeSubscription: useCallback((params) => {
      dispatch(userActions.upgradeUserSubscription(params));
    }, [dispatch]),
    resetError: useCallback(() => {
      dispatch(userActions.resetError());
    }, [dispatch]),
  };
};

export default useUserService;
