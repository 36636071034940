import colors from "@assets/colors";
import TypographyStyle from "@assets/typography";
import React from "react";
import { TouchableOpacity, StyleSheet, Text } from "react-native";
import { Button03Props } from "./types";

const Button02 = (props: Button03Props) => {
  const { onPress, style, disabled, children } = props;
  const backgroundColor = disabled && {
    backgroundColor: colors.border2,
  };
  
  return (
    <TouchableOpacity
      ref={props.ref}
      onPress={onPress}
      activeOpacity={0.8}
      disabled={disabled}
      style={[styles.buttonStyle, style, backgroundColor]}
    >
      {children || (
        <Text style={[styles.labelStyle, props.labelStyle]}>
          {props.label || "Next"}
        </Text>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  buttonStyle: {
    alignItems: "center",
    justifyContent: "center",
  },
  labelStyle: {
    ...TypographyStyle.buttonText,
    color: colors.white,
  },
});

export default React.memo(Button02);
