// DUCKS pattern
import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@src/ducks/store";

export interface AppStateType {
  screen: "pickNotaryPublic" | "submitNotaryPublic";
}

export const initialState: AppStateType = {
  screen: "pickNotaryPublic",
};

// Slice
export const themesSlice = createSlice({
  name: "themes",
  initialState,
  reducers: {
    setPickNotaryScreen: (state, action) => {
      state.screen = action.payload;
    },
  },
});

// Actions
export const themesActions = {
  setPickNotaryScreen: themesSlice.actions.setPickNotaryScreen,
};

// Selectors
export const selectedScreenPickNotary = (state: RootState) => state.themes.screen;

// Reducer
export default themesSlice.reducer;
