/* eslint-disable no-useless-escape */
import colors from "@assets/colors";
import { API_BASE_URL } from "@env";
import _ from "lodash";
import { PDFDocument, PageSizes } from "pdf-lib";
import React from "react";
import { signsecureDownloadDocument } from "./api";
import { getRoleLabel } from "./filter-helper";

const IMAGE_MIMETYPE = ["image/jpeg", "image/png"];

const DOC_MIMETYPE = ["application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/msword"];


export const dashboardDocuments = (array: any[]) => {
  return array.map((item: any) => {
    const auditTrail = item?.workflow?.auditTrail ?? [];
    const recent = auditTrail[auditTrail.length - 1];
    item.recentAction = recent?.details;
    item.status = item?.workflow?.status ?? "";
    return item;
  });
};

// DESCEND AUDITTRAIL
export const auditTrailDescending = (array: any[]) => {
  const arrayToSort = [...array];
  const newArr = arrayToSort.sort((a, b) => b.timestamp - a.timestamp);

  return newArr;
};

// CHOOSE FILE TO READABLE FILE
export const chooseFiles = (array: any[], accessToken: string) => new Promise((resolve, reject) => {
  const promises: any = [];

  _.forEach(array, (item) => {
    const promise = new Promise((innerResolve, innerReject) => {
      if(IMAGE_MIMETYPE.includes(item.mimeType)){
        convertImageToPDF(item)
          .then((res: any) => {
            innerResolve({
              size: item.size,
              file: item.file,
              name: item.name.replace(/\.[^\/.]+$/, ""),
              mimeType: "application/pdf",
              lastModified: item.lastModified,
              uri: res
            });
          })
          .catch(innerReject);
      } else if(DOC_MIMETYPE.includes(item.mimeType)) {
        
        const formData = new FormData();
        formData.append("file", item.file);
        const requestOptions = {
          method: "POST",
          body: formData,
          headers: new Headers({
            "Authorization": "Bearer " + accessToken, 
          }), 
        };

        fetch(`${API_BASE_URL}/directory/signsecure/doc-to-pdf`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            innerResolve({
              size: item.size,
              file: item.file,
              name: item.name.replace(/\.[^\/.]+$/, ""),
              mimeType: "application/pdf",
              lastModified: item.lastModified,
              uri: "data:application/pdf;base64," + result.data
            });
          }); 
      } else {
        innerResolve({
          size: item.size,
          name: item.name.replace(/\.[^\/.]+$/, ""),
          file: item.file,
          mimeType: item.mimeType,
          lastModified: item.lastModified,
          uri: item.uri
        });
      }
    });
    promises.push(promise);
  });

  Promise.all(promises)
    .then((result) => {
      resolve(result);
    })
    .catch((error) => {
      reject(error);
    });
});

// FILE TO READABLE FILE
export const dragFiles = (array: any[], accessToken: string) => new Promise((resolve, reject) => {
  const promises: any = [];

  _.forEach(array, (item) => {
    const promise = new Promise(async (innerResolve, innerReject) => {
      const reader = new FileReader();

      reader.readAsDataURL(item);
      reader.onload = async(e: any) => {
        const params = {
          size: item.size,
          name: item.name.replace(/\.[^\/.]+$/, ""),
          file: item,
          mimeType: item.type,
          lastModified: item.lastModified,
          lastModifiedDate: item.lastModifiedDate,
          uri: e.target.result,
        };

       
        if(IMAGE_MIMETYPE.includes(params.mimeType)) {
          const result = await convertImageToPDF(params);
          params.uri = result;
          params.mimeType = "application/pdf";
          innerResolve(params);
        } else if(DOC_MIMETYPE.includes(params.mimeType)) {
          const formData = new FormData();
          formData.append("file", item);
          console.log(item);
          const requestOptions = {
            method: "POST",
            body: formData,
            headers: new Headers({
              "Authorization": "Bearer " + accessToken, 
            }), 
          };

          fetch(`${API_BASE_URL}/directory/signsecure/doc-to-pdf`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
              params.uri = "data:application/pdf;base64," + result.data;
              params.mimeType = "application/pdf";
              innerResolve(params);
            });
          
        } else {
          innerResolve(params);
        }

      };
      reader.onerror = innerReject;
    });
    promises.push(promise);
  });

  Promise.all(promises)
    .then((result) => {
      resolve(result);
    })
    .catch((error) => {
      reject(error);
    });
});

// const convertDocToHTML = async (file: any) => {
//   return await mammoth.convertToHtml({arrayBuffer: file.arrayBuffer()}, {});
// }

// const convertHTMLtoPDF = async (html: any) => {
//   const pdfDoc = await PDFDocument.create();
//   const page = pdfDoc.addPage(PageSizes.Letter);
//   const { width, height } = page.getSize();

//   page.drawText(html, {
//     x: 50,
//     y: height - 50,
//     size: 12,
//     color: rgb(0, 0, 0)
//   });

//   const b64Chunk = await pdfDoc.saveAsBase64();
//   return "data:application/pdf;base64," + b64Chunk;
// }

// IMAGE TO PDF
const convertImageToPDF = async (file: any) => {
  const pdfDoc = await PDFDocument.create();
  let image;
  if (/jpe?g/i.test(file.mimeType)) image = await pdfDoc.embedJpg(file.uri);
  else if (/png/i.test(file.mimeType)) image = await pdfDoc.embedPng(file.uri);
  else throw Error("Please choose a JPEG or PNG file to proceed");

  const page = pdfDoc.addPage(PageSizes.Letter);
  const { width, height } = page.getSize();  // Get the width & height of the page

  const scaled = image.scaleToFit(width, height);

  page.drawImage(image, {
    width: scaled.width,
    height: scaled.height,
    x: (width - scaled.width) / 2,
    y: (height - scaled.height) / 2,
  });

  const b64Chunk = await pdfDoc.saveAsBase64();
  return "data:application/pdf;base64," + b64Chunk;
};

// FILE TO BASE64
export const toBase64 = (file: any) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = (e: any) => resolve(e.target.result);
  reader.onerror = reject;
});

// CONVERT ACCESSTOKEN TO USERID
export const parseJwt = (token: string) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(atob(base64).split("").map((c) => { 
    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(""));

  return JSON.parse(jsonPayload);
};

// CONVERT STRING BREAKLINE
export const convertStringBreakline = (value: string = "") => {
  return value
    .replaceAll("[breakline]", "\n")
    .replaceAll("[breakline2]", "\n\n");
};

/**
 * Converts a FileList object into an array of files.
 * @param {FileList} fileList - The FileList object to convert.
 * @returns {Array<File>} An array containing the files from the FileList object.
 */
export const convertFileListToArray = (fileList: any) => {
  const fileArray = [];

  for (const key in fileList) {
    if (fileList.hasOwnProperty(key)) {
      const file = fileList[key];
      fileArray.push(file);
    }
  }

  return fileArray;
};

// TRANSFORM SIGNSECURE DATA TO CREATE DOCUMENT FORM
export const parseSignSecureWorkflow = (document: any, accessToken: string) => new Promise((resolve, reject) => {
  if(document.type === "folder"){
    const promises: any = [];
    _.forEach(document.children, (childDoc) => {
      const promise = new Promise((innerResolve, innerReject) => {
        const file: any = {
          "id": childDoc.id,
          ref: React.createRef(),
          "privacyType": childDoc.privacyType,
          "isDraft": childDoc.isDraft,
          "size": childDoc.size,
          "name": childDoc.name,
          "description": childDoc.description,
          "path": {},
          "subject": childDoc.workflow.emailContent.subject,
          "message": childDoc.workflow.emailContent.message,
          "type": "application/pdf",
          "uri": childDoc.uri,
          "qrposition": childDoc.workflow.qrPosition,
          "trackId": childDoc.id,
          "parties": childDoc.workflow.parties,
          "reminders": childDoc.reminders,
          signatures: []
        };

        file.parties = _.map(childDoc.workflow.parties, (item: any, index: number) => {
          const randomColor = Math.random().toString(16).substr(-6);

          return {
            ...item, 
            fill: index === 0 ? colors.signature2 : `#${randomColor}`, 
            ref: React.createRef(), 
            role: getRoleLabel(item.role)
          };
        });
        file.signatures = _.reduce(childDoc.workflow.signatures, (result: any, value, key) => {
          const layerFields = value.map((item: any) => {
            const party = _.find(childDoc.workflow.parties, ["trackId", item.trackId]) || {};

            return { ...item.config, ...item,  email: party.email, };
          },[]);
    
          result.push({
            id: key.toString(),
            ref: React.createRef(),
            layerFields: layerFields
          });
    
          return result;
        },[]);

        signsecureDownloadDocument(childDoc.id, accessToken).then((base64: string) => {
          file.uri = base64;
          innerResolve(file);
        }).catch(innerReject);
      });

      promises.push(promise);
    });

    Promise.all(promises)
      .then((result) => {
        resolve({
          folderId: document.id,
          isShareToTeam: document.isShareToTeam,
          folderName: document.name,
          files: result,
        });
      })
      .catch((error) => {
        reject(error);
      });
  }else{
    const file: any = {
      ref: React.createRef(),
      "id": document.id,
      "isDraft": document.isDraft,
      "privacyType": document.privacyType,
      "size": document.size,
      "name": document.name,
      "description": document.description,
      "path": {},
      "subject": document.workflow.emailContent.subject,
      "message": document.workflow.emailContent.message,
      "type": "application/pdf",
      "uri": document.uri,
      "qrposition": document.workflow.qrPosition,
      "trackId": document.id,
      "parties": document.workflow.parties,
      "reminders": document.reminders,
      signatures: []
    };
  
    file.parties = _.map(document.workflow.parties, (item: any, index: number) => {
      const randomColor = Math.random().toString(16).substr(-6);

      return {
        ...item, 
        fill: index === 0 ? colors.signature2 : `#${randomColor}`, 
        ref: React.createRef(), 
        role: getRoleLabel(item.role)
      };
    });

    file.signatures = _.reduce(document.workflow.signatures, (result: any, value, key) => {
      const layerFields = value.map((item: any) => {
        const party = _.find(document.workflow.parties, ["trackId", item.trackId]) || {};

        return { ...item.config, ...item,  email: party.email, };
      },[]);

      result.push({
        id: key.toString(),
        ref: React.createRef(),
        layerFields: layerFields
      });

      return result;
    },[]);

    signsecureDownloadDocument(document.id, accessToken).then((base64: string) => {
      file.uri = base64;

      resolve({
        isShareToTeam: document.isShareToTeam,
        folderName: "",
        files: [file],
      });
    }).catch(reject);
  }
});
