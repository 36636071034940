import { useRoute } from "@react-navigation/native";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useDashboardService } from "@src/ducks/hooks";
import { selectedActiveDrawer } from "@src/ducks/slices/dashboard.slice";
import React, { createContext, useCallback, useState } from "react";

interface DrawerContextValue {
  sendActive: boolean;
  activeTab: string;
  activeDocument: string;
  addTeamSuccess: boolean;
  activeSecondScreen: string;
  search: string;
  onActiveTab: (value: string) => void;
  isDrawerVisible: boolean;
  isMaximize: boolean,
  setIsMaximize: (value: boolean) => void;
  onDrawerStatus: () => void;
  onSearchStateChange: (value: string) => void;
  onSetActiveSecondScreen: (value: string) => void;
  onAddTeamButton: (value: boolean) => void;
  onSetCreateDocumentScreen: (value: string) => void;
  onSetSendActive: (value: boolean) => void;
}

const DrawerContext = createContext<DrawerContextValue>({
  search: "",
  sendActive: false,
  addTeamSuccess: false,
  activeTab: "Dashboard",
  activeDocument: "",
  activeSecondScreen: "",
  onActiveTab: () => {},
  isDrawerVisible: false,
  isMaximize: false,
  setIsMaximize: () => {},
  onDrawerStatus: () => {},
  onSearchStateChange: () => {},
  onAddTeamButton: () => {},
  onSetActiveSecondScreen: () => {},
  onSetCreateDocumentScreen: () => {},
  onSetSendActive: () => {},
} as DrawerContextValue);

interface ProviderProps {
  children: React.ReactElement;
}

const DrawerProvider = ({ children }: ProviderProps) => {
  const route = useRoute<any>();
  const [search, setSearch] = useState<string>("");
  const { onDocumentChange } = useDashboardService();
  const [sendActive, setSendActive] = useState(false);
  const activeTab = useAppSelector(selectedActiveDrawer);
  const  { onActiveDrawerChange } = useDashboardService();
  const [isDrawerVisible, setDrawerStatus] = useState(false);
  const [activeDocument, setActiveDocument] = useState<string>("");
  const [activeSecondScreen, setSecondScreen] = useState<string>("");
  const [addTeamSuccess, setAddTeamSuccess] = useState<boolean>(false);
  const [isMaximize, setIsMaximize] = useState<boolean>(false);
  

  const onActiveTab = useCallback(
    (value: string) => {
      setSearch("");
      setSecondScreen("");
      setActiveDocument("");
      onActiveDrawerChange(value);
      setDrawerStatus(false);
    },
    [activeTab]
  );

  const onAddTeamButton = useCallback((value: boolean) => {
    setAddTeamSuccess(value);
  }, [addTeamSuccess]);

  const onSearchStateChange = useCallback(
    (value: string) => {
      setSearch(value);
    },[search]
  );

  const onSetCreateDocumentScreen = useCallback(
    (value: string) => {
      setActiveDocument(value);
    },[activeDocument]
  );

  const onSetActiveSecondScreen = useCallback(
    (value: string) => {
      setSecondScreen(value);
    },[activeSecondScreen]
  );

  const onDrawerStatus = useCallback(() => {
    setDrawerStatus(!isDrawerVisible);
  }, [isDrawerVisible]);

  const onSetSendActive = useCallback((value: boolean) => {
    setSendActive(value);
  }, [sendActive]);

  React.useEffect(() => {
    if(route?.params?.w){
      onActiveTab(route?.params?.activeTab);
      onDocumentChange({id: route?.params?.w});
    }
  },[]);

  const value = {
    search,
    sendActive,
    activeTab,
    activeDocument,
    addTeamSuccess,
    onActiveTab,
    isDrawerVisible,
    isMaximize,
    setIsMaximize,
    onDrawerStatus,
    onSearchStateChange,
    activeSecondScreen,
    onSetActiveSecondScreen,
    onSetCreateDocumentScreen,
    onSetSendActive,
    onAddTeamButton
  };

  return (
    <DrawerContext.Provider value={value}>
      {children}
    </DrawerContext.Provider>
  );
};

export { DrawerContext, DrawerProvider };

