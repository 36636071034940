
import { SagaIterator } from "@redux-saga/core";
import { call, delay, put, select, takeEvery } from "redux-saga/effects";

// Types
import { AppConfig } from "../types";

// API
import { appConfig, updateAppConfig } from "@src/utils/api";

// Slice
import { WEB_BASE_URL } from "@env";
// import moment from "moment";
import moment from "@src/utils/moment-timezone-helper";
import { authActions } from "../slices/auth.slice";
import { settingsActions } from "../slices/settings.slice";
import { dashboardActions } from "../slices/dashboard.slice";
import { selectSubscriptionTeamID, selectUserDetails, userActions } from "../slices/user.slice";

function* handleUpdateMaintenance(action: {
    type: typeof settingsActions.updateMaintenanceRequest;
    payload: AppConfig;
  }): SagaIterator {
  try {
    // const session = yield select(selectAuthSession);
    const user = yield call(updateAppConfig, action.payload);
    yield put(settingsActions.updateMaintenanceSuccess(user.data));
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    if (message.includes("Unauthorized")) {
      yield put(authActions.logout());
    }
    yield put(settingsActions.updateMaintenanceFailure({ message }));
    yield delay(1000);
    yield put(settingsActions.updateMaintenanceFailure({}));
  }
}

// Watcher Saga
function* userWatcherSaga(): SagaIterator {
  yield takeEvery(settingsActions.updateMaintenanceRequest.type, handleUpdateMaintenance);
}

export default userWatcherSaga;
