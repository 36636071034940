import colors from "@assets/colors";
import Icon from "@expo/vector-icons/MaterialIcons";
import { useNavigation } from "@react-navigation/native";
import { largeSize, mobileSize } from "@src/utils/screensize-helper";
import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import useStyles from "./styles.css";

const ArrowBack = () => {
  const styles = useStyles();
  const isLarge = largeSize();
  const isMobile = mobileSize();
  const navigation = useNavigation<any>();
  const size = React.useMemo(() => {
    if(isMobile) {
      return 15;
    }else if(isLarge) {
      return 18;
    }
    return 23;
  },[isMobile]);

  return (
    <View style={styles.v_arrow_back_button}>
      <TouchableOpacity activeOpacity={0.8} style={styles.btn_arrow_back} onPress={() => navigation.goBack()}>
        <Icon name="arrow-back" size={size} color={isMobile ? colors.black : colors.grayPrimary} />
        <Text numberOfLines={1} style={styles.header_title_top}>Back to Sign In</Text>
      </TouchableOpacity>
    </View>
  );
};

export default ArrowBack;
