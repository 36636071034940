import React, { useRef, useState } from "react";
import { Text, View } from "react-native";
import useStyles from "./styles.css";
import numeral from "numeral";
import { ForgotPasswordContext } from "./provider";

const Timer = (props: any) => {
  const styles = useStyles();
  const timerId = useRef<any>(null);
  const [timer, setTimer] = useState(180);
  const { verifyFailed } = React.useContext(ForgotPasswordContext);

  React.useEffect(() => {
    if (props.sessionId) {
      setTimer(180);
    }
  }, [props.sessionId]);

  React.useEffect(() => {
    if (!timer) {
      timerId.current && clearInterval(timerId.current);
      props.onTimeEnd(true);
      return;
    }

    timerId.current = setInterval(() => {
      setTimer((lastTimerCount) => lastTimerCount - 1);
    }, 1000);

    return () => clearInterval(timerId.current);
  }, [timer]);

  return (
    <View style={styles.error_container}>
      {timer > 0 && <Text style={[styles.t_error]}>
        {numeral(timer).format("00:00")}
      </Text>}
      {verifyFailed.message && <Text style={[styles.t_error]}>
        {verifyFailed.message}
      </Text>}
    </View>
  );
};

export default Timer;
