import useOrientationHelper from "@src/utils/orientation-helper";
import { tabletSize } from "@src/utils/screensize-helper";
import React from "react";
import { View } from "react-native";
import Background from "./background";
import Login from "./login";
import Logo from "./logo";
import LogoTop from "./logo-top";
import { SignInProvider } from "./provider";
import useStyles from "./styles.css";

const SignIn = () => {
  const styles = useStyles();
  const isTablet = tabletSize();
  const isLandScape = useOrientationHelper();

  return (
    <SignInProvider>
      <View style={isLandScape ? styles.container : styles.containerPortrait}>
        <Background />
        <View style={isLandScape ? styles.v_body : styles.v_body_portrait}>
          {isTablet ? <LogoTop/> : <Logo /> }
          <Login />
        </View>
      </View>
    </SignInProvider>
  );
};

export default SignIn;
