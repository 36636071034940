import React from "react";

type Props = {
    capture?: boolean,
    height: number,
    width: number,
    cameraRef: any,
    canvasRef: any,
    cameraOnPlay: () => void,
}

const FaceDetectionCamera = (props: Props) => {
  return (
    <div style={{ display: "flex", overflow:"hidden", justifyContent: "center" }}>
      <video
        id="face-camera"
        muted
        autoPlay
        playsInline
        ref={props.cameraRef} 
        height={props.height} 
        width={props.width} 
        onPlay={props.cameraOnPlay} 
        style={{ transform: "scale(-1,1)" }} 
      />
      <canvas ref={props.canvasRef} 
        style={{ opacity: props.capture ? 0 : 1, position: "absolute", transform: "scale(-1,1)" }} />
    </div>
  );
};

export default FaceDetectionCamera;

