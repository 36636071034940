import React  from "react";
import DrawerItemHeader from "../drawer-header";
import useStyles from "./styles.css";
import { View } from "react-native";
import DrawerItemBody from "./body";

const DrawerBody = () => {
  const styles = useStyles();

  return (
    <View style={[styles.drawer_item_container]}>
      {/* RANDOLF STICKY HEADER COMMENTED OUT */}
      {/* <DrawerItemHeader /> */}
      <View style={[styles.v_body]}>
        <DrawerItemBody />
      </View>
    </View>
  );
};

export default DrawerBody;
