import { CommonActions, useNavigation, useRoute } from "@react-navigation/native";
import { AuthContext } from "@src/AuthProvider";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectAuthLoggedIn } from "@src/ducks/slices/auth.slice";
import { useFetchPost } from "@src/utils/api/hooks";
import _ from "lodash";
import React from "react";
import { ActivityIndicator, Text, View } from "react-native";
import useStyles from "./styles.css";

const TeamVerification = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const navigation = useNavigation<any>();
  const { data, error, runRequest } = useFetchPost();
  const isLoggedIn = useAppSelector(selectAuthLoggedIn);
  const { onErrorMessage } = React.useContext(AuthContext);

  React.useEffect(() => {
    const teamId = route?.params?.teamId ?? "";
    const routes = "/subscription-team/member/confirm/" + teamId;
    const params = {
      inviteToken: route?.params?.token ?? "",
    };
    runRequest(routes, params);
  }, []);

  React.useEffect(() => {
    if(!_.isEmpty(data)){
      navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [
            { name: isLoggedIn ? "Home" : "Signin" },
          ],
        })
      );
    }
    if(!_.isEmpty(error)){
      onErrorMessage(error.message || error.error || "Something went wrong");
      navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [
            { name: isLoggedIn ? "Home" : "Signin" },
          ],
        })
      );
    }
  }, [data, error]);

  return (
    <View style={styles.container}>
      <View style={styles.v_signup}>
        <Text style={styles.t_title}>Account Verifying</Text>
        <Text style={[styles.t_note, { marginTop: 10 }]}>Please wait while verifying your account.</Text>
        <ActivityIndicator size="small" color="black" style={{marginTop: 10}} />
      </View>
    </View>
  );
};

export default TeamVerification;
