import React from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";
import useStyles from "./styles.css";
import images from "@assets/images";
import colors from "@assets/colors";
import Icon from "@assets/svg";

const Header = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={() => navigation.goBack()}
        activeOpacity={0.9}
        style={styles.btn_style}
      >
        <Icon name="back" fill={colors.white} />
        <Text style={styles.t_back}>Back</Text>
      </TouchableOpacity>
      <Image
        style={styles.logo_signsecure}
        source={images.signsecurev2_black}
        resizeMode="contain"
      />
    </View>
  );
};

export default Header;
