import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      overflow: 'hidden',
      alignItems: 'center',
      backgroundColor: colors.white,
    },
    v_body: {
      zIndex: 2,
      marginTop: 90,
      width: '65%',
      borderRadius: 4,
      paddingHorizontal: 50,
      backgroundColor: 'white',
      paddingVertical: 60,
      shadowColor: "gray",
      shadowOffset: { width: 1, height: 1 },
      shadowOpacity: 0.6,
      shadowRadius: 5,
    },
    i_style: { width: 90, height: 90 },
    t_title_policy: {
      marginTop: 15,
      fontFamily: 'DMSans',
      color: colors.blue5,
      fontSize: 28,
      fontWeight: '700'
    },
    t_title: {
      marginTop: 15,
      fontFamily: 'DMSans',
      color: colors.blue5,
      fontSize: 20,
      fontWeight: '700'
    },
    t_note: {
      fontFamily: 'DMSans',
      color: '#000000',
      fontSize: 16,
      lineHeight: 23,
      textAlign: 'justify',
      marginTop: 15
    },
    btn_dashboard: { marginTop: 40, minWidth: 300 },
    strong: {
      fontWeight: '900'
    },
    indent: {
      marginLeft: 10
    }
  },
  {
    [DEVICE_SIZES.LG]: {
      i_style: { width: 120, height: 120 },
      t_title: {
        fontSize: 20,
      },
    },
    [DEVICE_SIZES.MD]: {
      v_body: {
        width: '70%',
      },
    },
    [DEVICE_SIZES.SM]: {
      container: {
        justifyContent: 'flex-start',
      },
      v_body: {
        width: '90%',
        marginTop: 40,
        marginHorizontal: 20,
        marginBottom: 40,
        paddingHorizontal: 20,
        paddingVertical: 30,
      },
      t_title: {
        marginTop: 30,
        fontSize: 20,
      },
      t_note: {
        fontSize: 14,
        lineHeight: 20,
      },
    },
    [DEVICE_SIZES.XS]: {
      container: {
        justifyContent: 'flex-start',
      },
      v_body: {
        width: '90%',
        marginTop: 40,
        marginHorizontal: 20,
        marginBottom: 40,
        paddingHorizontal: 20,
        paddingVertical: 30,
      },
      t_title: {
        marginTop: 30,
        fontSize: 20,
      },
      t_note: {
        fontSize: 14,
        lineHeight: 20,
      },
    },
  }
);

export default useStyles;
