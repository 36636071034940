import { useForgotService } from "@src/ducks/hooks";
import React from "react";
import PasswordChanged from "./password-changed";
import ResetPassword from "./reset-password";

const Screens = () => {
  const { resetSuccess } = useForgotService();

  if (resetSuccess) {
    return <PasswordChanged />;
  }

  return <ResetPassword />;
};

export default Screens;
