import { useAppDispatch, useAppSelector } from "@src/ducks/ducksHook";
import { authActions, selectedAuthAccessToken } from "@src/ducks/slices/auth.slice";
import { useCallback, useState } from "react";
import { CurrentApi } from "../request";
import { ErrorValue, FetchGetProps } from "./useFetchGet";

export const useFetchDelete = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const accessToken = useAppSelector(selectedAuthAccessToken);
  const [error, setError] = useState<ErrorValue>({} as ErrorValue);

  const runRequest = useCallback(
    async (routes: string) => {
      try {
        setLoading(true);
        CurrentApi.setToken(accessToken);
        const result = await CurrentApi.delete(`${routes}`);

        setData(result);
        setLoading(false);
      } catch (err: any) {
        let message = err.message || err.error || "Something went wrong";
        message = message.replace(/^HttpException:\s*/, "");
        setError({ message });
        setLoading(false);

        if (message.includes("Unauthorized")) {
          dispatch(authActions.logout());
        }
      }
    },
    [accessToken, dispatch]
  );

  const runReset = useCallback(() => {
    setError({} as ErrorValue);
    setData(null);
  },[]);

  return {
    loading,
    data,
    error,
    runReset,
    runRequest,
  } as FetchGetProps;
};
