import colors from "@assets/colors";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    drawer_item_header: {
      height: 109,
      paddingTop: 12,
      flexDirection: "row",
      alignItems: "center",
      borderBottomWidth: 0.5,
      borderBottomColor: colors.border6,
      backgroundColor: colors.white,
    },
    drawer_mobile_container: {
      flex: 1,
      flexWrap: "wrap",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    drawer_mobile_logo: {
      height: 42,
      width: 204,
      tintColor: colors.white,
    },
    v_height: { height: 40 },
  },
  {
    [DEVICE_SIZES.LG]: {
      drawer_item_header: { height: 81.75, paddingTop: 9 },
      v_height: { height: 30.75 },
    },
    [maxSize(DEVICE_SIZES.MD)]: {
      v_height: { height: 0 },
      drawer_item_header: {
        height: 60,
        paddingLeft: 20,
        paddingRight: 20,
        justifyContent: "flex-end",
        backgroundColor: colors.colorPrimary,
      },
    },
  }
);

export default useStyles;
