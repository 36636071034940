import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { colors } from "theme";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.white,
    },
    containerPortrait: { alignItems: "center", justifyContent: "center" },
    scrollview: { flex: 1, width: "100%" },
    v_body: {
      flex: 1,
      width: "100%",
      flexDirection: "row",
    },
    v_body_portrait: {
      width: "100%",
      marginHorizontal: 20,
    },
    v_login: {
      width: "50%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      paddingHorizontal: "12%",
      backgroundColor: colors.white,
    },

    signInCenter: { alignItems: "center", marginTop: 20 },
    txtNote1: {
      fontFamily: "DMSans-Bold",
      fontSize: 40,
      lineHeight: 52,
      textAlign: "center",
      color: colors.blue,
    },
    v_field: { marginTop: 20, width: "100%" },
    ti_input: { marginTop: 31 },
    txtNote2: {
      fontFamily: "DMSans",
      fontSize: 16,
      lineHeight: 24,
      marginTop: 31,
      textAlign: "center",
      color: colors.black10,
    },
    btn_signin: {
      marginTop: 50,
      height: 60,
      backgroundColor: colors.blue,
    },
    lbl_signin: {
      fontFamily: "Montserrat-Bold",
    },
    v_height: { height: 20 },
  },
  {
    [DEVICE_SIZES.LG]: {
      ti_input: { marginTop: 20 },
      txtNote1: {
        fontSize: 30,
        lineHeight: 40,
      },
      txtNote2: {
        fontSize: 15,
        lineHeight: 22,
        marginTop: 27,
      },
      btn_signin: {
        marginTop: 40,
        height: 50,
      },
    },
    [DEVICE_SIZES.MD]: {
      v_body: {
        width: "100%",
        alignItems: "flex-start",
        marginHorizontal: 20,
      },
      v_login: {
        minWidth: "100%",
        width: "100%",
        paddingTop: 80,
        justifyContent: "flex-start",
        paddingHorizontal: "20%",
      },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      v_body: {
        width: "100%",
        alignItems: "flex-start",
        marginHorizontal: 20,
      },
      v_body_portrait: {
        width: "100%",
        alignItems: "center",
        marginHorizontal: 20,
      },
      v_login: {
        width: "100%",
        paddingTop: 30,
        justifyContent: "flex-start",
        paddingHorizontal: 20,
      },
      txtNote1: {
        fontSize: 22,
        lineHeight: 25,
      },
      txtNote2: {
        marginTop: 4,
        fontSize: 16,
        lineHeight: 20,
      },
      btn_signin: {
        marginTop: 40,
        height: 45,
      },
    },
  }
);

export default useStyles;
