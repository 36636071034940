import { Button01 } from "@src/components/button";
import { TextInput02 } from "@src/components/textinput";
import useOrientationHelper from "@src/utils/orientation-helper";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Text, View } from "react-native";
import UnawaProduct from "../signin/unawaProduct";
import ErrorView from "./error";
import { ForgotPasswordContext } from "./provider";
import useStyles from "./styles.css";

interface ErrorProps {
  password: string;
  confirmPassword: string;
}

const ErrorValue = {
  password: "",
  confirmPassword: "",
};

const NewPassword = () => {
  const styles = useStyles();
  const errorRef = React.useRef<any>(null);
  const isLandScape = useOrientationHelper();
  const [password, setNewpassword] = useState<string>("");
  const [error, setError] = useState<ErrorProps>(ErrorValue);
  const [confirmPassword, setConfirmpassword] = useState<string>("");
  const { loading, onNewPassword } = useContext(ForgotPasswordContext);

  const onForgotPass = () => {
    const newError: any = {};
    if (!password) {
      newError.password = "Required.";
    }else if (password.length <= 5) {
      newError.password = "Password must be at least six characters";
    }else if (!confirmPassword) {
      newError.confirmPassword = "Required.";
    }else if (password !== confirmPassword) {
      newError.confirmPassword = "Confirm password do not match";
    }

    setError(newError);

    if (_.isEmpty(newError)) {
      onNewPassword({ password, confirmPassword });
    }
  };

  useEffect(() => {
    setError({} as ErrorProps);
    errorRef.current?.onClear();
  }, [password]);

  return (
    <View style={isLandScape ? styles.v_forgot_landscape : styles.v_forgot} >
      <View style={styles.signInCenter}>
        <Text style={styles.txtNote1}>New Password</Text>
        <Text style={styles.txtNote2}>
        Set the new password for your account{"\n"}so you can login and access all features.
        </Text>
      </View>
      <ErrorView ref={errorRef} />
      <View style={styles.v_field}>
        <TextInput02
          hasPassword
          value={password}
          label="Enter new password"
          onChangeText={setNewpassword}
          placeholder="Enter new password"
        />
        <Text style={styles.errorColor}>{error.password}</Text>
        <TextInput02
          hasPassword
          value={confirmPassword}
          label="Confirm password"
          onChangeText={setConfirmpassword}
          placeholder="Confirm password"
        />
        <Text style={styles.errorColor}>{error.confirmPassword}</Text>
        <Button01
          label="Update Password"
          onPress={onForgotPass}
          isLoading={loading}
          style={{ marginTop: 30 }}
          labelStyle={styles.lbl_signin}
        />
      </View>
      {isLandScape ? null : <UnawaProduct termsStyle={styles.termsStyle} />}
    </View>
  );
};

export default NewPassword;
