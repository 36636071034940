import { WEB_BASE_URL } from "@env";
import { blobToBase64 } from "base64-blob";
import QRCodeStyling from "qr-code-styling";
import React from "react";
import { View } from "react-native";
import Options from "./options.json";

const qrCode = new QRCodeStyling(Options as any);

const svgToPng = (svgData: string, width: number, height: number) => new Promise((resolve) => {
  // create canvas in memory(not in DOM)
  const canvas = document.createElement("canvas");
  // get canvas context for drawing on canvas
  const context: any = canvas.getContext("2d");
  // set canvas size
  canvas.width = width;
  canvas.height = height;
  // create image in memory(not in DOM)
  const image = new Image();
  // later when image loads run this
  image.onload = function () { // async (happens later)
    // clear canvas
    context.clearRect(0, 0, width, height);
    // draw image with SVG data to canvas
    context.drawImage(image, 0, 0, width, height);
    // snapshot canvas as png
    const pngData = canvas.toDataURL("image/png");
    resolve(pngData);
  }; // end async
  // start loading SVG data into in memory image
  image.src = svgData;
});

export const QRStyling = async(width: number, height: number, id: string) => {
  qrCode.update({
    data: `${WEB_BASE_URL}/document/${id}`,
    width: width,
    height: height,
  });

  const blob: any = await qrCode.getRawData("svg");
  const base64 =  await blobToBase64(blob);

  return await svgToPng(base64, width, height);
};

function QRCode(props: any) {
  const ref = React.useRef<any>(null);

  React.useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  React.useEffect(() => {
    qrCode.update({
      data: props.value,
      width: props.width,
      height: props.height,
    });
  }, [props.value, props.width, props.height]);

  return <View ref={ref} />;
}

export default QRCode;
