import colors from "@assets/colors";
import { Platform, StyleSheet, TextStyle } from "react-native";

export const typography = {
  DMSansRegular: Platform.select({ default: "DMSans" }),
  MontserratRegular: Platform.select({ default: "Montserrat" }),
  RobotoRegular: Platform.select({ default: "Roboto" }),
  LatoRegular: Platform.select({ default: "Lato" }),
};

interface TypographyStyles {
  heading1Bold: TextStyle;
  heading2Bold: TextStyle;
  heading3Bold: TextStyle;
  Heading5Bold: TextStyle;
  heading4Bold: TextStyle;
  heading5Bold: TextStyle;
  heading6Bold: TextStyle;
  heading7Bold: TextStyle;  
  heading3Regular: TextStyle;
  heading4Regular: TextStyle;
  heading6Regular: TextStyle;
  ButtonLRegular: TextStyle;
  ButtonSRegular: TextStyle;
  Body1Regular: TextStyle;
  Body2Regular: TextStyle;
  Body3Regular: TextStyle;
  BodySRegular: TextStyle;
  fieldsTitle: TextStyle;
  buttonText: TextStyle;
  googleText: TextStyle; // can be converted as socmed text style
  copyRightButton: TextStyle;
  copyRightButtonMobile: TextStyle;
  copyRightText: TextStyle;
  uploadDocsText: TextStyle;
  ButtonBrowse: TextStyle;
}

const TypographyStyle = StyleSheet.create<TypographyStyles>({
  heading1Bold: {
    flex: 1,
    fontFamily: typography.DMSansRegular,
    fontSize: 14,
    lineHeight: 16,
    fontWeight: "700",
    color: colors.white,
  },
  heading2Bold: {
    fontFamily: typography.DMSansRegular,
    fontSize: 45,
    lineHeight: 52,
    fontWeight: "700",
    textAlign: "center",
    color: colors.black3,
  },
  heading3Bold: {
    fontFamily: typography.DMSansRegular,
    fontSize: 24,
    lineHeight: 32,
    textAlign: "center",
    color: colors.white,
  },
  Heading5Bold: {
    fontFamily: typography.DMSansRegular,
    fontSize: 20,
    lineHeight: 24,
    fontWeight: "700",
    color: colors.black3,
  },

  heading4Bold: {
    fontFamily: typography.DMSansRegular,
    fontSize: 24,
    lineHeight: 28,
    fontWeight: "700",
    textAlign: "center",
    color: colors.black2,
  },
  heading5Bold: {
    fontFamily: typography.DMSansRegular,
    fontSize: 16,
    lineHeight: 24,
    fontWeight: "700",
    color: colors.blue2,
  },
  heading6Bold: {
    fontFamily: typography.DMSansRegular,
    fontSize: 50,
    lineHeight: 54,
    fontWeight: "700",
    color: colors.blue,
    textAlign: "center",
  },
  heading7Bold: {
    fontFamily: "DMSans-Bold",
    fontSize: 40,
    lineHeight: 52,
    textAlign: "center",
    color: colors.blue,
  },
  heading3Regular: {
    fontFamily: typography.DMSansRegular,
    fontSize: 16,
    lineHeight: 24,
    fontWeight: "400",
    textAlign: "center",
    color: colors.black10,
  },
  heading4Regular: {
    fontFamily: typography.DMSansRegular,
    fontSize: 24,
    lineHeight: 32,
    fontWeight: "400",
    textAlign: "center",
    color: colors.black3,
  },
  heading6Regular: {
    fontFamily: typography.DMSansRegular,
    fontSize: 16,
    lineHeight: 28,
    fontWeight: "400",
    textAlign: "center",
    color: colors.black3,
  },
  ButtonLRegular: {
    fontFamily: typography.DMSansRegular,
    fontSize: 18,
    lineHeight: 24,
    fontWeight: "400",
    color: colors.black3,
  },
  ButtonSRegular: {
    fontFamily: typography.DMSansRegular,
    fontSize: 14,
    lineHeight: 16,
    fontWeight: "400",
    color: colors.grayPrimary,
  },
  Body1Regular: {
    fontFamily: typography.DMSansRegular,
    fontSize: 18,
    lineHeight: 20,
    color: colors.black3,
  },
  Body2Regular: {
    fontFamily: typography.DMSansRegular,
    fontSize: 16,
    lineHeight: 18,
    fontWeight: "400",
    color: colors.darkGray,
  },
  Body3Regular: {
    fontFamily: typography.DMSansRegular,
    fontSize: 16,
    lineHeight: 18,
    fontWeight: "700",
    color: colors.black3,
  },
  BodySRegular: {
    fontFamily: typography.DMSansRegular,
    fontSize: 14,
    lineHeight: 16,
    fontWeight: "400",
    color: colors.black3,
  },
  
  fieldsTitle: {
    fontFamily: typography.DMSansRegular,
    fontSize: 14,
    lineHeight: 14,
    fontWeight: "400",
    color: colors.black3,
  },
  buttonText: {
    fontFamily: typography.MontserratRegular,
    fontSize: 14,
    fontWeight: "700",
    lineHeight: 16,
    color: colors.white,
  },
  googleText: {
    fontFamily: typography.RobotoRegular,
    fontSize: 14,
    fontWeight: "500",
    lineHeight: 16.5,
    color: colors.black3,
  },
  copyRightButton: {
    fontFamily: "DMSans-Bold",
    fontSize: 14,
    lineHeight: 21,
  },
  copyRightButtonMobile: {
    fontFamily: "DMSans-Bold",
    fontSize: 12,
    lineHeight: 21,
  },
  copyRightText: {
    fontSize: 14,
    lineHeight: 21,
    color: colors.white,
    fontFamily: typography.LatoRegular,
  },
  uploadDocsText: {
    fontSize: 14,
    lineHeight: 21,
    fontWeight: "700",
    textDecorationLine: "underline",
    color: colors.purple,
    fontFamily: "Montserrat-Bold",
  },
  ButtonBrowse: {
    fontFamily: "DMSans-Bold",
    fontSize: 10,
    lineHeight: 14,
    color: colors.white,
  },
});

export default TypographyStyle;
