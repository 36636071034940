import { useNavigation } from "@react-navigation/native";
import { Button01 } from "@src/components/button";
import { TextInput02 } from "@src/components/textinput";
import { NavigationScreenProps } from "@src/navigators/NavigatorTypes";
import useOrientationHelper from "@src/utils/orientation-helper";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Text, View } from "react-native";
import UnawaProduct from "../signin/unawaProduct";
import ErrorView from "./error";
import { ForgotPasswordContext } from "./provider";
import useStyles from "./styles.css";

interface ErrorProps {
  email: string;
  password: string;
}

const ErrorValue = {
  email: "",
  password: "",
};

const InputEmail = () => {
  const styles = useStyles();
  const errorRef = React.useRef<any>(null);
  const isLandScape = useOrientationHelper();
  const [error, setError] = useState<ErrorProps>(ErrorValue);
  const {email, loading, onSetEmail, onSendEmail } = React.useContext(ForgotPasswordContext);
  const navigation = useNavigation<NavigationScreenProps<"Signin">>();

  const onForgotPass = () => {
    const newError: any = {};
    if (!email) {
      newError.email = "Email is required.";
    }

    setError(newError);

    if (_.isEmpty(newError)) {
      onSendEmail();
    }
  };

  const onSignIn = () => {
    navigation.navigate("Signin");
  };

  useEffect(() => {
    setError({} as ErrorProps);
    errorRef.current?.onClear();
  }, [email]);

  return (
    <View style={isLandScape ? styles.v_forgot_landscape : styles.v_forgot} >
      <View style={styles.signInCenter}>
        <Text style={styles.txtNote1}>Forgot Password</Text>
        <Text style={styles.txtNote2}>
          Enter your email for the verification process, {"\n"} we will send 4 digits code to your email.
        </Text>
      </View>
      <ErrorView ref={errorRef} />
      <View style={styles.v_field}>
        <TextInput02
          value={email}
          label="Email"
          labelStyle={styles.labelStyle}
          error={error.email}
          onChangeText={onSetEmail}
          placeholder="Enter your email"
        />
        <Button01
          label="Continue"
          isLoading={loading}
          onPress={onForgotPass}
          style={styles.btn_signin}
          labelStyle={styles.lbl_signin}
        />
        <Text style={styles.t_signup}>
          Need to return to the login page?{" "}
          <Text style={styles.t_signup_2} onPress={onSignIn}>
            Back to Sign In
          </Text>
        </Text>
      </View>
      {isLandScape ? null : <UnawaProduct termsStyle={styles.termsStyle} />}
    </View>
  );
};

export default InputEmail;
