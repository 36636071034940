import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import { StyleSheet } from "react-native";
import colors from "@assets/colors";
export const ITEM_HEIGHT = 45;

const useStyles = CreateResponsiveStyle(
  {
    modal: { ...StyleSheet.absoluteFillObject },
    dropdown: {
      width: 400,
      zIndex: 1,
      position: "absolute",
      borderRadius: 4,
      backgroundColor: "white",
      justifyContent: "center",
      shadowColor: "gray",
      shadowOffset: { width: 1, height: 1 },
      shadowOpacity: 0.6,
      shadowRadius: 10,
    },
    itemSeparator: {
      height: 1,
      marginHorizontal: 10,
      backgroundColor: colors.offwhite,
    },
    v_item: {
      height: ITEM_HEIGHT,
      flexDirection: "row",
      alignItems: "center",
      paddingHorizontal: 20,
    },
    v_item_active: { backgroundColor: colors.offwhite },
    t_item: {
      flex: 1,
      fontSize: 15,
      lineHeight: 20,
      color: colors.black,
      fontFamily: "DMSans",
    },
  },
  {
    [DEVICE_SIZES.LG]: {
      t_item: { fontSize: 11, lineHeight: 10.5 },
    },
  }
);

export default useStyles;
