import { useAppSelector } from "@src/ducks/ducksHook";
import { selectSubscriptionActive, selectSubscriptionMaxUsers } from "@src/ducks/slices/user.slice";
import DrawerListData from "@src/utils/mockdata/drawer-list";
import React, { useContext, useState } from "react";
import { View } from "react-native";
import { DEVICE_SIZES, useDeviceSize } from "rn-responsive-styles";
import { DrawerContext } from "../../drawer-provider";
import { DrawerComponentContext } from "../provider";
import DrawerItem from "./drawer-item";
import useStyles from "./styles.css";


const DrawerList = () => {
  const styles = useStyles();
  const device_size = useDeviceSize();
  const maxUser = useAppSelector(selectSubscriptionMaxUsers);
  const { activeTab, onActiveTab } = useContext(DrawerContext);
  const { onMinimizeState } = useContext(DrawerComponentContext);
  const isSubscribeActive = useAppSelector(selectSubscriptionActive);
  const [hover, setHover] = useState(
    new Array<boolean>(DrawerListData.length).fill(false)
  );

  const DrawerAccessList = React.useMemo(() => {
    return DrawerListData.reduce((result: any, value: any) => {
      if(!isSubscribeActive && value.name === "Manage Users"){
        // do nothing
      }else if(maxUser <= 1 && value.name === "Manage Users"){
        // do nothing
      }else{
        result.push(value);
      }

      return result;
    },[]);
  },
  [isSubscribeActive, maxUser]);

  const handleHover = React.useCallback((eventName: string, idx: number) => {
    const isHover = eventName === "hoverIn";
    setHover((prevState) => ({
      ...prevState,
      [idx]: isHover,
    }));
  },[]);

  const onToggle = (itemName : string) => {
    if (DEVICE_SIZES.XS === device_size || DEVICE_SIZES.SM === device_size || DEVICE_SIZES.MD === device_size) {
      onMinimizeState();
    }
    onActiveTab(itemName);
  };

  return (
    <View style={styles.v_margintop}>
      {DrawerAccessList.map((item: any, index: number) => {
        const isHover = hover[index];
        const isActive = item.name === activeTab;

        return (
          <DrawerItem
            key={`tabs${index}`}
            isActive={isActive}
            isHover={isHover}
            item={item}
            onPress={() => onToggle(item.name)}
            onPressIn={() => handleHover("hoverIn", index)}
            onPressOut={() => handleHover("hoverOut", index)}
            onHoverIn={() => handleHover("hoverIn", index)}
            onHoverOut={() => handleHover("hoverOut", index)}
          />
        );
      })}
    </View>
  );
};

export default DrawerList;
