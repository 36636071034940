import useOrientationHelper from "@src/utils/orientation-helper";
import { tabletSize } from "@src/utils/screensize-helper";
import React from "react";
import { View } from "react-native";
import Background from "../signin/background";
import Logo from "../signin/logo";
import LogoTop from "../signin/logo-top";
import { ForgotPasswordProvider } from "./provider";
import Screens from "./screens";
import useStyles from "./styles.css";

const ForgotPassword = () => {
  const styles = useStyles();
  const isTablet = tabletSize();
  const isLandScape = useOrientationHelper();

  return (
    <View style={isLandScape ? styles.container : styles.containerPortrait}>
      <Background />
      <ForgotPasswordProvider>
        <View style={isLandScape ? styles.v_body : styles.v_body_portrait}>
          {isTablet ? <LogoTop /> : <Logo /> }
          <Screens />
        </View>
      </ForgotPasswordProvider>
    </View>
  );
};

export default ForgotPassword;
