import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    drawer_tab_container: {
      height: 60,
      minWidth: 250,
      paddingLeft: 23,
      flexDirection: "row",
      alignItems: "center",
    },
    activeBG: {
      backgroundColor: colors.white,
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
    },
    activeText: {
      color: "#3F3F3F", 
      fontWeight: "700", 
      fontSize: 20
    },
    activeHover: {
      backgroundColor: "rgba(255,255,255,0.1)",
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
    },
    drawer_tab_label: {
      fontSize: 20,
      lineHeight: 14,
      marginLeft: 20,
      fontFamily: "DMSans",
      color: colors.white,
    },
    v_margintop: { 
      marginTop: 37,
    },
  },
  {
    [DEVICE_SIZES.LG]: {
      drawer_tab_container: { paddingLeft: 17.25, height: 45 },
      drawer_tab_label: { fontSize: 15, lineHeight: 10.5, marginLeft: 26.25 },
      activeBG: { borderTopLeftRadius: 15, borderBottomLeftRadius: 15 },
      activeHover: { borderTopLeftRadius: 15, borderBottomLeftRadius: 15 }, 
      activeText: {
        color: "#3F3F3F", 
        fontWeight: "700", 
        fontSize: 15
      },
    },
    [DEVICE_SIZES.MD]: {
      drawer_tab_label: { fontSize: 14, lineHeight: 14 },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      v_margintop: { marginTop: 15 },
      drawer_tab_label: { fontSize: 12, lineHeight: 14 },
      drawer_tab_container: { paddingHorizontal: 24 },
    },
  }
);

export default useStyles;
