import useOrientationHelper from "@src/utils/orientation-helper";
import React from "react";
import { View } from "react-native";
import Background from "../signin/background";
import Logo from "../signin/logo";
import FaceScan from "./face-scan";
import LogoTop from "./logo-top";
import { SignInProvider } from "./provider";
import useStyles from "./styles.css";
import { tabletSize } from "@src/utils/screensize-helper";

const SignInWithFaceID = () => {
  const styles = useStyles();
  const isTablet = tabletSize();
  const isLandScape = useOrientationHelper();

  return (
    <SignInProvider>
      <View style={isLandScape ? styles.container : styles.containerPortrait}>
        <Background />
        <View style={isLandScape ? styles.v_body : styles.v_body_portrait}>
          {isTablet ? <LogoTop /> : <Logo />}
          <FaceScan />
        </View>
      </View>
    </SignInProvider>
  );
};

export default SignInWithFaceID;
