/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { View, Text, ScrollView } from 'react-native';
import { Button01 } from '@src/components/button';
import useStyles from './styles.css';
import Header from './header';

const PrivacyPolicy = () => {
  const styles = useStyles();

  return (
    <View style={styles.container}>
      <Header />
      <View style={styles.v_body}>
        <ScrollView>
          <Text style={styles.t_title_policy}>Privacy Policy</Text>
          <Text style={styles.t_note}>
            We at <span style={styles.strong}>UNAWA</span> are committed to protecting and respecting your privacy by collecting and processing your personal information and sensitive personal information in accordance with applicable data privacy laws and regulations (“Data Privacy Laws”), including the Philippine Data Privacy Act of 2012 (“DPA”) and its implementing rules and regulations (“DPA IRR”) (collectively, the “Privacy Laws”).
            {'\n'}
            {'\n'}
            This Privacy Notice sets out the manner by which UNAWA collects and processes your personal data.
            {'\n'}
            {'\n'}
            This Privacy Notice has been prepared and used as of January 2020 (“Notice Date”). We reserve the right to amend this Privacy Notice from time to time to reflect changing legal requirements or our processing practices. Any such changes will be posted on this website and will be effective upon posting.
          </Text>

          <Text style={styles.t_title}>The personal information we collect and process</Text>
          <Text style={styles.t_note}>
            As of the Notice Date, the personal information we collect and process may include:
            {'\n'}
            {'\n'}
            <View style={styles.indent}>
              <Text>1. Information that will allow us to contact you, such as your name, address, email address, and telephone and mobile numbers;</Text>
              <Text>2. Information that will allow us to verify your identity, such as government-issued identification number and details, e.g. social security numbers, tax identification numbers, and passport details; and</Text>
              <Text>3. Other personal information that will be necessary to assist us in providing our services to you.</Text>
            </View>
          </Text>

          <Text style={styles.t_title}>How we collect personal information</Text>
          <Text style={styles.t_note}>
            In order to provide our services to you, we will collect personal data directly from you or your organization when you or your organization:
            {'\n'}
            {'\n'}
            <View style={styles.indent}>
              <Text>1. Register to use our services;</Text>
              <Text>2. Engage in the use of our services;</Text>
              <Text>3. Access, browse, visit, use, or contact us through our website and other online or social media platform;</Text>
              <Text>4. Contact us or provide such personal information to us in other circumstances.</Text>
            </View>
            {'\n'}
            {'\n'}
            In some cases, we may collect data about you from third-party sources, such as from public records.
          </Text>

          <Text style={styles.t_title}>Purpose and manner of using personal information</Text>
          <Text style={styles.t_note}>
            Any personal information we collect are manually and/or electronically used, stored, recorded, or otherwise processed exclusively for purposes of:
            {'\n'}
            {'\n'}
            <View style={styles.indent}>
              <Text>1. Rendering services to you or your organization, as clients;</Text>
              <Text>2. Conveying information on regulatory updates, legal matters, or other compliance matters relevant to you and/or your organization or business;</Text>
              <Text>3. Managing our business relationship with you and/or your organization, including processing payments, accounting, auditing, billing and collection and related support services;</Text>
              <Text>4. Acting in compliance with our legal obligations, including with respect to anti-money laundering and sanctions checks;</Text>
              <Text>5. Managing and securing the access to our offices, systems and online platforms;</Text>
              <Text>6. Complying with legal and regulatory requirements, including mandatory regulatory submissions and court orders as may be applicable; and</Text>
              <Text>7. Any other purpose related to the foregoing or for any purpose for which you provided your personal information to UNAWA.</Text>
              
              <Text>{'\n'}Your personal information will be disclosed to or shared with the following entities, for the purpose of providing additional legal review or advice on the particular services that you have availed from UNAWA:{'\n'}{'\n'}</Text>
              
              <View style={styles.indent}>
                <Text>1. Puyat Jacinto & Santos (PJS) Law;</Text>
                <Text>2. PJS Corporate Support, Inc.;</Text>
              </View>
              <Text>{'\n'}
              Except as otherwise provided above, we do not share your personal information with any other third parties, unless necessary to the services we provide or when you specifically consent to the transfer or sharing of your personal information. In such a case, the transfer of your personal information will be done in accordance with applicable Privacy Laws. You will be notified of such transfer or sharing, and we will take appropriate safeguards to ensure the integrity and protection of the personal information being transferred.</Text>
              
              <Text>{'\n'}We will delete your personal data when it is no longer reasonably required for the above purposes or when you withdraw your consent (where applicable), provided that we are not legally required or otherwise permitted to continue to hold such data. We may retain your personal data for an additional period to the extent we deem it necessary to comply with regulatory compliance requirements or assert or defend legal claims during any relevant retention period.</Text>
              {'\n'}
              {'\n'}
            </View>
          </Text>

          <Text style={styles.t_title}>How we secure and protect personal information</Text>
          <Text style={styles.t_note}>
            We take the necessary and appropriate organizational, physical and technical security measures against unauthorized or unlawful processing of your personal data and against accidental loss or destruction of, or damage to, your personal data.
            {'\n'}
            {'\n'}
            We also limit access to your personal information to the staff assigned to your matter or those personnel who require such data in the performance of their functions.
          </Text>

          <Text style={styles.t_title}>Rights as Data Subjects</Text>
          <Text style={styles.t_note}>
            As data subjects, you have the following rights over your personal information:
            {'\n'}
            {'\n'}
            <View style={styles.indent}>
              <Text>
                <span style={styles.strong}>1. Right to Be Informed </span>
                — You have the right to be informed whether your personal data shall be, are being, or have been processed, including whether automated decision-making and profiling will be involved in the processing.
              </Text>
              {'\n'}
              <Text>
                <span style={styles.strong}>2. Right to Object </span>
                — You can object to processing of your personal data, including processing for direct marketing, automated processing, or profiling.
              </Text>
              {'\n'}
              <Text>
                <span style={styles.strong}>3. Right to Access </span>
                — You have the right to reasonable access to the following, upon demand:
                {'\n'}
                <View style={styles.indent}>
                  <Text>a. contents of your personal data that was processed;</Text>
                  <Text>b. sources from which personal data was obtained;</Text>
                  <Text>c. names and addresses of recipients of the personal data;</Text>
                  <Text>d. manner by which such data was processed;</Text>
                  <Text>e. reasons for the disclosure of the personal data to recipients, if any;</Text>
                  <Text>f. information on automated processes where your personal data will, or is likely to, be made as the sole basis for any decision that significantly affects or will affect you;</Text>
                  <Text>g. date when your personal data was last accessed and modified; and</Text>
                  <Text>h. the designation, name or identity, and address of the personal information controller.</Text>
                </View>
              </Text>
              {'\n'}
              <Text>
                <span style={styles.strong}>4. Right to Rectification </span>
                — You have the right to dispute the inaccuracy or error in your personal data, unless the request is vexatious or otherwise unreasonable.
              </Text>
              {'\n'}
              <Text>
                <span style={styles.strong}>5. Right to Erasure or Blocking </span>
                — You have the right to suspend, withdraw or order the blocking, removal or destruction of your personal information from our filing system, under the circumstances allowed under Data Privacy Laws.
              </Text>
              {'\n'}
              <Text>
                <span style={styles.strong}>6. Right to Damages </span>
                — You have the right to be indemnified for any damages sustained due to such inaccurate, incomplete, outdated, false, unlawfully obtained or unauthorized use of your personal data.
              </Text>
              {'\n'}
              <Text>
                <span style={styles.strong}>7. Right to Portability </span>
                — You have the right to obtain a copy of such your personal data in an electronic or structured format, which is commonly used and allows for your further use, in case your personal data was processed through electronic means and in a structured and commonly used format.
              </Text>
            </View>
          </Text>

          <Text style={styles.t_note}>Any requests, concerns, or notices relating to exercising your rights as indicated above may be sent in writing to the following:</Text>
          {'\n'}
          <View style={[styles.t_note, styles.indent]}>
            <ul>
              <li>Email: gabb.ai@unawa.asia</li>
              <li>Website: www.unawa.asia</li>
              <li>Address: 10F 8 Rockwell, Hidalgo corner Plaza Drives, Rockwell Center, Makati City, Philippines</li>
            </ul>
          </View>
        </ScrollView>
      </View>
      <View style={{ height: 50 }} />
    </View>
  );
};

export default PrivacyPolicy;
