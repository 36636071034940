const DrawerList = [
  {
    name: "Dashboard",
    icon: "Dashboard",
    page: "Dashboard"
  },
  {
    name: "Manage Subscribers",
    icon: "Subscribers",
    page: "Manage Subscribers",
  },
  {
    name: "Promo Codes",
    icon: "Promo",
    page: "Manage Promo Codes",
  },
  {
    name: "Settings",
    page: "General Settings",
    icon: "Settings",
  }
];



export default DrawerList;