import SVGIcon from "@assets/svg";
import { largeSize } from "@src/utils/screensize-helper";
import React, { useContext } from "react";
import { Pressable, View } from "react-native";
import Animated, { Extrapolation, interpolate, useAnimatedStyle } from "react-native-reanimated";
import { DrawerContext } from "../drawer-provider";
import { DrawerComponentContext } from "./provider";

const Logo = () => {
  const isLargeSize = largeSize();
  const { animated } = useContext(DrawerComponentContext);
  const { onActiveTab } = useContext(DrawerContext);

  const size = React.useMemo(() => {
    if(isLargeSize){
      return { logo: {
        width: 37,
        height: 44,
      }, signsecure: {
        width: 185,
        height: 61.5,
      }
      };
    }

    return { logo: {
      width: 42,
      height: 49,
    }, signsecure: {
      width: 270,
      height: 56,
    }
    };
  },[isLargeSize]);

  const animateStyle = useAnimatedStyle(() => {
    const opacity = interpolate(
      animated.value,
      [360, 110],
      [1, 0],
      Extrapolation.CLAMP,
    );

    return {
      opacity,
      position: "absolute"
    };
  }, []);

  const animateStyle2 = useAnimatedStyle(() => {
    const opacity = interpolate(
      animated.value,
      [120, 110],
      [0, 1],
      Extrapolation.CLAMP,
    );

    return {
      opacity,
      marginLeft: 10,
      position: "absolute"
    };
  }, []);
  
  return (
    <Pressable android_ripple={null} android_disableSound={true} onPress={() => onActiveTab("Dashboard")}>
      {() => (
        <View style={{ ...size.signsecure, alignItems: "center" }}>
          <Animated.View style={animateStyle}>
            <SVGIcon name="SignsecureLogoDrawer" {...size.signsecure} /> 
          </Animated.View>

          {/* //collapsed */}
          <Animated.View style={animateStyle2}>
            <SVGIcon name="signsecure-logo-blue" {...size.logo} /> 
          </Animated.View>
        </View>
      )}
    </Pressable>

  );
};

export default Logo;
