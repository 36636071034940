import { useCallback } from "react";
import { useAppDispatch } from "../ducksHook";
import {
  dashboardActions
} from "../slices/dashboard.slice";

type ServiceOperators = {
  onDrawerEnable: (params: boolean) => void;
  onActiveDrawerChange: (params: string) => void;
  onDocumentChange: (params: any) => void;
};

export const useDashboardService = (): Readonly<ServiceOperators> => {
  const dispatch = useAppDispatch();

  return {
    onDrawerEnable: useCallback((params) =>  
      dispatch(dashboardActions.drawerEnable(params)),
    [dispatch]
    ),
    onActiveDrawerChange: useCallback(
      (params) =>  dispatch(dashboardActions.activeDrawer(params)),
      [dispatch]
    ),
    onDocumentChange: useCallback(
      (params) =>  dispatch(dashboardActions.setDocument(params)),
      [dispatch]
    ),
  };
};

export default useDashboardService;
