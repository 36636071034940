import images from "@assets/images";
import { useNavigation } from "@react-navigation/native";
import { Button01 } from "@src/components/button";
import { NavigationScreenProps } from "@src/navigators/NavigatorTypes";
import useOrientationHelper from "@src/utils/orientation-helper";
import React from "react";
import { Image, Text, View } from "react-native";
import UnawaProduct from "../signin/unawaProduct";
import useStyles from "./styles.css";

const Success = () => {
  const styles = useStyles();
  const isLandScape = useOrientationHelper();
  const navigation = useNavigation<NavigationScreenProps<"Signin">>();

  return (
    <View style={isLandScape ? styles.v_forgot_landscape : styles.v_forgot} >
      <View style={[styles.signInCenter, { marginTop: 0 }]}>
        <Image style={styles.i_fp_success} source={images.ic_forgot_success} resizeMode="contain"/>
        <Text style={[styles.txtNote1, { marginTop: 20 }]}>Successfully</Text>
        <Text style={[styles.txtNote2, { marginTop: 20 }]}>
        Your password has been reset successfully
        </Text>
      </View>
      <Button01
        label="Sign in"
        style={styles.btn_signin}
        labelStyle={styles.lbl_signin}
        onPress={() => navigation.navigate("Signin")}
      />
      {isLandScape ? null : <UnawaProduct termsStyle={styles.termsStyle} />}
    </View>
  );
};

export default Success;
