import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import { colors } from "theme";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      overflow: "hidden",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.white,
    },
    containerPortrait: { alignItems: "center", justifyContent: "center" },

    v_body: {
      width: "100%",
      height: "100%",
      flexDirection: "row",
    },
    v_body_portrait: {
      width: "100%",
      alignItems: "flex-start",
      marginHorizontal: 20,
    },
    v_signup: {
      width: "50%",
      alignItems: "center",
      paddingHorizontal: "8%",
      justifyContent: "center",
      backgroundColor: colors.white,
    },
    v_signup_landscape: {
      width: "50%",
      alignItems: "center",
      justifyContent: "center",
      paddingHorizontal: "9%",
      backgroundColor: colors.white,
    },
    i_style: { width: 60, height: 70 },
    t_title: {
      fontSize: 40,
      marginTop: 56,
      lineHeight: 52,
      color: colors.blue,
      textAlign: "center",
      fontFamily: "DMSans-Bold",
    },
    t_note: {
      fontFamily: "DMSans",
      color: colors.black2,
      fontSize: 24,
      lineHeight: 28,
      marginTop: 51,
      textAlign: "center",
    },
    btn_dashboard: { marginTop: 40, minWidth: 300 },
    btn_label: { fontFamily: "DMSans-Bold"},
  },
  {
    [DEVICE_SIZES.LG]: {
      i_style: { width: 47, height: 53 },
      t_title: { fontSize: 30, lineHeight: 39, marginTop: 42 },
      t_note: { fontSize: 18, lineHeight: 21, marginTop: 32 },
      btn_dashboard: { height: 45},
      btn_label: { fontSize: 10.5, lineHeight: 12 }
    },
    [DEVICE_SIZES.MD]: {
      v_body_portrait: {
        width: "100%",
        alignItems: "center",
        marginHorizontal: 20,
        height: "80%"
      },
      t_title: { fontSize: 30, lineHeight: 39, marginTop: 42 },
      t_note: { fontSize: 18, lineHeight: 21, marginTop: 32 },
      v_signup: {
        width: "100%",
        paddingTop: 100,
        alignItems: "center",
        paddingHorizontal: "10%",
        justifyContent: "center",
        backgroundColor: colors.white,
      },
    },
    [DEVICE_SIZES.SM]: {
      container: {
        justifyContent: "flex-start",
      },
      v_body: {
        width: "90%",
        marginTop: 20,
        marginHorizontal: 20,
        marginBottom: 40,
        paddingHorizontal: 20,
        paddingVertical: 30,
      },
      v_body_portrait: {
        width: "100%",
        alignItems: "center",
        marginHorizontal: 20,
        height: "80%"
      },
      v_signup: {
        paddingTop: 80,
        minWidth: "100%",
        maxWidth: "100%",
        paddingHorizontal: 20,
      },
      t_title: {
        marginTop: 30,
        fontSize: 22,
      },
      t_note: {
        fontSize: 14,
        lineHeight: 20,
      },
    },
    [DEVICE_SIZES.XS]: {
      container: {
        justifyContent: "flex-start",
      },
      v_body: {
        width: "90%",
        marginTop: 20,
        marginHorizontal: 20,
        marginBottom: 40,
        paddingHorizontal: 20,
        paddingVertical: 30,
      },
      v_body_portrait: {
        width: "100%",
        alignItems: "center",
        marginHorizontal: 20,
        height: "80%"
      },
      v_signup: {
        paddingTop: 80,
        minWidth: "100%",
        maxWidth: "100%",
        paddingHorizontal: 20,
      },
      t_title: {
        marginTop: 30,
        fontSize: 22,
      },
      t_note: {
        fontSize: 14,
        lineHeight: 20,
      },
    },
  }
);

export default useStyles;
