import { combineReducers } from "@reduxjs/toolkit";
import auth from "./slices/auth.slice";
import signup from "./slices/signup.slice";
import forgot from "./slices/forgot.slice";
import themes from "./slices/themes.slice";
import user from "./slices/user.slice";
import signsecure from "./slices/signsecure.slice";
import dashboard from "./slices/dashboard.slice";
import settings from "./slices/settings.slice";

const reducer = combineReducers({
  auth,
  signup,
  forgot,
  user,
  themes,
  signsecure,
  dashboard,
  settings,
}); // ADD SLICES HERE

export default reducer;
