import App from "@src/App";
import { LogBox } from "react-native";
import { enableLegacyWebImplementation } from "react-native-gesture-handler";

enableLegacyWebImplementation(true);


LogBox.ignoreLogs([
  "StyleSheet.compose",
  "Animated: `useNativeDriver`",
  "setNativeProps is deprecated",
]);

export default App;
