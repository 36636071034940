import useOrientationHelper from "@src/utils/orientation-helper";
import React from "react";
import { View } from "react-native";
import UnawaProduct from "../../signin/unawaProduct";
import ArrowBack from "./arrow-back";
import Mask from "./mask";
import useStyles from "./styles.css";

const FaceScan = () => {
  const styles = useStyles();
  const isLandScape = useOrientationHelper();

  return (
    <View style={styles.container} >
      <ArrowBack />
      <View style={styles.v_center}>
        <Mask />
        {!isLandScape && <UnawaProduct termsStyle={[styles.termsStyle]} />}
      </View>
    </View>
  );
};

export default FaceScan;
