import React, { useEffect, useRef, useState } from "react";
import { Text, View } from "react-native";
import Animated, {
  Extrapolation,
  interpolate,
  runOnJS,
  useAnimatedReaction,
  useAnimatedStyle,
  useSharedValue
} from "react-native-reanimated";
import { SignInContext } from "../provider";
import useStyles from "./styles.css";

const ProgressBar = () => {
  const styles = useStyles();
  const animated = useSharedValue(0);
  const interval = useRef<any>(null);
  const [progress, setProgress] = useState(0);
  const { scanSuccess, progressValue } = React.useContext(SignInContext);
  const animatedStyle = useAnimatedStyle(() => {
    const width = interpolate(
      animated.value,
      [0, 90, 100],
      [0, 90, 100],
      Extrapolation.CLAMP
    );

    return {
      width: `${width}%`,
    };
  });

  useEffect(() => {
    interval.current = progressValue;
    return () => clearInterval(interval.current);
  }, [progressValue]);

  useEffect(() => {
    if(scanSuccess){
      interval.current && clearInterval(interval.current);
      animated.value = 100;
    }
  }, [scanSuccess]);

  useAnimatedReaction(
    () => animated.value,
    (value, previousValue) => {
      if (value !== previousValue) {
        runOnJS(setProgress)(value);
      }
    }
  );

  return (
    <View style={styles.v_progress}>
      <View style={[styles.progress]} >
        <Animated.View style={[styles.progress_active, animatedStyle]} />
      </View>
      <Text style={[styles.t_percentage]}>{progress}% Recognised</Text>
    </View>
  );
};

export default ProgressBar;
