import { useCallback } from "react";
import { useAppDispatch } from "../ducksHook";
import { signsecureActions } from "../slices/signsecure.slice";

// Types
import _ from "lodash";
import { CancelDocument, SignSecureInputs } from "../types";

interface Operators {
  saveDocumentRequest: () => void;
  createDocumentRequest: () => void;
  importDocumentRequest:(value: any) => void;
  viewDocumentRequest: (value: any) => void;
  viewDocumentChange: (value: any) => void;
  signDocumentRequest: (value: any) => void;
  approveDocumentRequest: (value: any) => void;
  cancelDocumentRequest: (value: CancelDocument) => void;
  declineDocumentRequest: (value: CancelDocument) => void;
  rejectDocumentRequest: (value: CancelDocument) => void;
  setSignsecureInputs: (value: SignSecureInputs) => void;
  resetError: () => void;
  onResetSignSecure: () => void;
  setPdfEncryptPassword:(value : any) => void;
}

export const useSignsecureService = (): Readonly<Operators> => {
  const dispatch = useAppDispatch();

  return {
    saveDocumentRequest: useCallback(() => {
      dispatch(signsecureActions.saveDocumentRequest());
    },[dispatch]),
    createDocumentRequest: useCallback(() => {
      dispatch(signsecureActions.createDocumentRequest());
    },[dispatch]),
    importDocumentRequest: useCallback((value) => {
      dispatch(signsecureActions.importDocumentRequest(value));
    },[dispatch]),
    viewDocumentRequest: useCallback((value) => {
      dispatch(signsecureActions.viewDocumentRequest(value));
    },[dispatch]),
    viewDocumentChange: useCallback((value) => 
      dispatch(signsecureActions.viewDocumentSuccess(value)),
    [dispatch]),
    signDocumentRequest: useCallback((value) => {
      dispatch(signsecureActions.affixSignatureRequest(value));
    },[dispatch]),
    approveDocumentRequest: useCallback((value) => {
      dispatch(signsecureActions.approveDocumentRequest(value));
    },[dispatch]),
    cancelDocumentRequest: useCallback((value) => {
      dispatch(signsecureActions.cancelDocumentRequest(value));
    },[dispatch]),
    declineDocumentRequest: useCallback((value) => {
      dispatch(signsecureActions.declineDocumentRequest(value));
    },[dispatch]),
    rejectDocumentRequest: useCallback((value) => {
      dispatch(signsecureActions.rejectDocumentRequest(value));
    },[dispatch]),
    setSignsecureInputs: useCallback(
      (value) => {
        const clone = _.cloneDeep(value.files);
        const newFile = clone.map((item: any) => {
          delete item.ref;
          const parties = item.parties.map((party: any) => {
            delete party.ref;
            return party;
          });
          const signatures = item.signatures.map((sign: any) => {
            delete sign.ref;
            return sign;
          });
          return {...item, parties, signatures};
        });
        value.files = newFile;
        dispatch(signsecureActions.saveSignsecureInputs(value));
      }, [dispatch]),
    resetError: useCallback(() => {
      dispatch(signsecureActions.resetError());
    }, [dispatch]),
    onResetSignSecure: useCallback(() => {
      dispatch(signsecureActions.resetSignSecure());
    }, [dispatch]),
    setPdfEncryptPassword: useCallback((value) => {
      dispatch(signsecureActions.pdfEncryptPassword(value));
    },[dispatch]),
  };
};

export default useSignsecureService;
