import { useAppDispatch, useAppSelector } from "@src/ducks/ducksHook";
import { authActions, selectedAuthAccessToken } from "@src/ducks/slices/auth.slice";
import { useCallback, useEffect, useState } from "react";
import { CurrentApi } from "../request";
import { ErrorValue } from "./useFetchPost";

export type FetchPatchProps = {
  loading: boolean;
  data: any;
  error: ErrorValue;
  runRequest: (routes: string, params: object, contentType?: any) => void;
  runReset: () => void;
}

export const useFetchPatch = (initialData = {}) => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState<ErrorValue>({} as ErrorValue);
  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState<boolean>(false);
  const accessToken = useAppSelector(selectedAuthAccessToken);

  const runRequest = useCallback(
    async (routes: string, params: any, contentType: any = "") => {
      try {
        setLoading(true);
        setData(initialData);
        CurrentApi.setToken(accessToken);
        if(contentType && contentType === "multipart/form-data"){
          CurrentApi.setContentType(contentType);
        }
        const result = await CurrentApi.patch(`${routes}`, params);

        setLoading(false);
        setData({status: result.status, ...result.data});
      } catch (err: any) {
        let message = err.message || err.error || "Something went wrong";
        message = message.replace(/^HttpException:\s*/, "");
        setLoading(false);
        setError({ message });

        if (message.includes("Unauthorized")) {
          dispatch(authActions.logout());
        }
      }
    },
    [accessToken, dispatch]
  );

  const runReset = useCallback(() => {
    setError({} as ErrorValue);
    setData(initialData);
  },[]);

  useEffect(() => {
    if(loading){
      setError({} as ErrorValue);
    }
  },[loading]);

  return {
    loading,
    data,
    error,
    runRequest,
    runReset,
  } as FetchPatchProps;
};
