import { useAuthService } from "@src/ducks/hooks";
import React, { createContext, useCallback, useState } from "react";

interface ContextValue {
  scanSuccess: boolean;
  scanFailed: boolean;
  enableCamera: boolean;
  enableScanning: boolean;
  progressValue : number;
  handleEnableCamera: (value: boolean) => void;
  handleScanning: (value: boolean) => void;
  handleScanFailed: () => void;
  handleScanComplete: () => void;
  handleProgressValue: (value : number) => void;
}

const SignInContext = createContext<ContextValue>({} as ContextValue);

interface SignInProviderProps {
  children: React.ReactElement;
}

const SignInProvider = ({ children }: SignInProviderProps) => {
  const { onFaceIDLogin } = useAuthService();
  const [scanFailed, setScanFailed] = useState(false);
  const [scanSuccess, setScanSuccess] = useState(false);
  const [enableCamera, setEnableCamera] = useState(false);
  const [enableScanning, setEnableScanning] = useState(false);
  const [progressValue, setProgressValue] = useState(0);

  const handleEnableCamera = useCallback(() => {
    setScanFailed(false);
    setEnableCamera(true);
  },[enableCamera]);
  const handleScanning = useCallback(setEnableScanning,[enableScanning]);
  const handleScanFailed = useCallback(() => {
    setScanFailed(true);
    setEnableCamera(false);
  },[scanFailed]);
  const handleScanComplete = useCallback(() => {
    setScanSuccess(true);
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      onFaceIDLogin();
    }, 1000);
  },[scanSuccess]);

  const handleProgressValue = useCallback(setProgressValue,[progressValue]);
  
  const value = {
    enableCamera,
    enableScanning,
    scanFailed,
    scanSuccess,
    progressValue,
    handleEnableCamera,
    handleScanning,
    handleScanFailed,
    handleScanComplete,
    handleProgressValue
  };

  return (
    <SignInContext.Provider value={value}>{children}</SignInContext.Provider>
  );
};

export { SignInContext, SignInProvider };

