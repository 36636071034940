import React from "react";
import { ForgotPasswordContext } from "../provider";
import useStyles from "../styles.css";
import { Text } from "react-native";

const Error = React.forwardRef((_, ref: any) => {
  const styles = useStyles();
  const [message, setMessage] = React.useState("");
  const { failed, newpasswordFailed } = React.useContext(ForgotPasswordContext);

  React.useImperativeHandle(ref, () => ({
    onClear: () => setMessage(""),
  }));

  React.useEffect(() => {
    if (failed?.message) {
      setMessage(failed?.message);
    }
    if (newpasswordFailed?.message) {
      setMessage(newpasswordFailed?.message);
    }
  }, [failed?.message, newpasswordFailed?.message]);

  if (!message) {
    return null;
  }

  return <Text style={[styles.t_error, { marginTop: 20 }]}>{message}</Text>;
});

export default React.memo(Error);
