import React from "react";
import { TouchableOpacity, Text, StyleSheet } from "react-native";
import colors from "@assets/colors";
import Icon from "@assets/svg";

type BackButtonProps = {
  onPress?: any;
  label?: string;
};

function BackButton(props: BackButtonProps) {
  return (
    <TouchableOpacity
      onPress={props.onPress}
      activeOpacity={0.9}
      style={styles.btn_style}
    >
      <Icon name="back" fill={colors.purple} />
      <Text style={styles.t_back}>{props.label || "Back"}</Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  btn_style: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  t_back: { fontFamily: "DMSans-Bold", color: colors.purple, fontSize: 18 },
});

export default BackButton;
