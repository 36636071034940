import { Button01 } from "@src/components/button";
import useOrientationHelper from "@src/utils/orientation-helper";
import _ from "lodash";
import React, { useContext, useState } from "react";
import { Text, View } from "react-native";
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from "react-native-confirmation-code-field";
import UnawaProduct from "../signin/unawaProduct";
import { ForgotPasswordContext } from "./provider";
import useStyles from "./styles.css";
import Timer from "./timer";

const CELL_COUNT = 4;

const Verification = () => {
  const styles = useStyles();
  const isLandScape = useOrientationHelper();
  const [value, setValue] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [timeEnd, setTimeEnd] = useState<boolean>(false);
  const ref = useBlurOnFulfill({value, cellCount: CELL_COUNT});
  const {generated, loading, onSendEmail, onVerifyCode, verifyFailed } = useContext(ForgotPasswordContext);
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  });
  
  const onForgotPass = () => {
    if(timeEnd){
      onSendEmail();
      setTimeEnd(false);
      return;
    }

    if (value.length !== 4) {
      setError(true);
    }else{
      onVerifyCode(value);
    }
  };

  React.useEffect(() => {
    setError(false);
  }, [value]);

  return (
    <View style={isLandScape ? styles.v_forgot_landscape : styles.v_forgot} >
      <View style={styles.signInCenter}>
        <Text style={styles.txtNote1}>Verification</Text>
        <Text style={styles.txtNote2}>
        Enter your 4 digits code that you received on your email.
        </Text>
      </View>
      <View style={{ marginTop: 40 }}>
        <CodeField
          ref={ref}
          {...props}
          value={value}
          cellCount={CELL_COUNT}
          onChangeText={setValue}
          keyboardType="number-pad"
          textContentType="oneTimeCode"
          renderCell={({index, symbol, isFocused}) => (
            <Text
              key={index}
              style={[styles.cell, 
                isFocused && styles.focusCell, 
                error && styles.errorCell,
                !_.isEmpty(verifyFailed.message) && styles.errorCell]}
              onLayout={getCellOnLayoutHandler(index)}>
              {symbol || (isFocused ? <Cursor /> : null)}
            </Text>
          )}
        />
        <Timer sessionId={generated} onTimeEnd={setTimeEnd} />
        <Text style={styles.expiredOTP}>
          {timeEnd ? "OTP has expired" : ""}
        </Text>
        <Button01
          label={timeEnd ? "Resend Code" : "Verify"}
          isLoading={loading}
          onPress={onForgotPass}
          style={{width: "100%"}}
          labelStyle={styles.lbl_signin}
        />
      </View>
      {isLandScape ? null : <UnawaProduct termsStyle={styles.termsStyle} />}
    </View>
  );
};

export default Verification;
