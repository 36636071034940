import images from "@assets/images";
import { Button01 } from "@src/components/button";
import useOrientationHelper from "@src/utils/orientation-helper";
import { tabletSize } from "@src/utils/screensize-helper";
import React from "react";
import { Image, Text, View } from "react-native";
import Background from "../signin/background";
import Logo from "../signin/logo";
import LogoTop from "../signin/logo-top";
import useStyles from "./styles.css";

const VerificationSuccess = (props: any) => {
  const styles = useStyles();
  const isTablet = tabletSize();
  const isLandScape = useOrientationHelper();
  const title = "Account Verified";
  const notes =
    "Your email has been verified. Start accessing your documents using your account.";
  const btnLabel = "Go to Dashboard";

  return (
    <View style={isLandScape ? styles.container : styles.containerPortrait}>
      <Background />
      <View style={isLandScape ? styles.v_body : styles.v_body_portrait}>
        {isTablet ? <LogoTop /> : <Logo /> }
        <View style={isLandScape ? styles.v_signup_landscape : styles.v_signup }>
          <Image
            style={styles.i_style}
            source={images.check}
            resizeMode="stretch"
          />
          <Text style={styles.t_title}>{title}</Text>
          <Text style={[styles.t_note, { marginTop: 10 }]}>{notes}</Text>
          <Button01
            onPress={props.onPress}
            style={styles.btn_dashboard}
            label={btnLabel}
            labelStyle={styles.btn_label}
          />
        </View>
      </View>
    </View>
  );
};

export default VerificationSuccess;
