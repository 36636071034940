import React from "react";
import { TouchableOpacity, StyleSheet, Text, ActivityIndicator } from "react-native";
import TypographyStyle from "@assets/typography";
import { Button03Props } from "./types";
import colors from "@assets/colors";

const Button04 = (props: Button03Props) => {
  const { onPress, style, disabled, children, isLoading } = props;

  const disableStyle = disabled && {
    backgroundColor: colors.border2,
  };

  return (
    <TouchableOpacity
      onPress={onPress}
      activeOpacity={0.8}
      disabled={disabled || isLoading}
      style={[styles.buttonStyle, style, disableStyle]}
    >
      {children || (
        <>
          {isLoading && <ActivityIndicator
            animating
            size="small"
            color={props.color || colors.white}
          />}
          <Text style={[styles.labelStyle, props.labelStyle]}>
            {props.label || "Loading"}
          </Text>
        </>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  buttonStyle: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  labelStyle: {
    ...TypographyStyle.buttonText,
    color: colors.white,
    marginLeft: 4
  },
});

export default React.memo(Button04);
