import React from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";
import useStyles from "./styles.css";
import images from "@assets/images";
import colors from "@assets/colors";
import Icon from "@assets/svg";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectAuthLoggedIn } from "@src/ducks/slices/auth.slice";

const Header = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const isLoggedIn = useAppSelector(selectAuthLoggedIn);

  const onBack = () => {
    navigation.navigate(isLoggedIn ? "Home" : "Signin");
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={onBack}
        activeOpacity={0.9}
        style={styles.btn_style}
      >
        <Icon name="back" fill={colors.white} />
        <Text style={styles.t_back}>Back</Text>
      </TouchableOpacity>
      <Image
        source={images.signsecurev2_black}
        style={styles.drawer_mobile_logo}
        resizeMode="contain"
      />
    </View>
  );
};

export default Header;
