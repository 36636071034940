import React from "react";
import InputEmail from "./input-email";
import NewPassword from "./new-password";
import { ForgotPasswordContext } from "./provider";
import Success from "./success";
import Verification from "./verification";

const Screens = () => {
  const { screen } = React.useContext(ForgotPasswordContext);

  switch(screen){
  case "verification":
    return <Verification />;
  case "new-password":
    return <NewPassword />;
  case "success":
    return <Success />;
  default:
    return <InputEmail />;
  }
};

export default Screens;
