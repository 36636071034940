import React, { createContext, useCallback, useState } from "react";

interface ContextValue {
  screen: string;
  updateScreen: (value: string) => void;
}

const SignInContext = createContext<ContextValue>({
  screen: "",
  updateScreen: () => {},
});

interface SignInProviderProps {
  children: React.ReactElement;
}

const SignInProvider = ({ children }: SignInProviderProps) => {
  const [screen, setScreen] = useState("");

  const updateScreen = useCallback(
    (value: string) => {
      setScreen(value);
    },
    [screen]
  );

  const value = {
    screen,
    updateScreen,
  };

  return (
    <SignInContext.Provider value={value}>{children}</SignInContext.Provider>
  );
};

export { SignInContext, SignInProvider };

