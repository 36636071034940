import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import { colors } from "theme";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.white,
    },
    containerPortrait: { alignItems: "center", justifyContent: "center" },
    v_body: {
      width: "100%",
      height: "100%",
      flexDirection: "row",
    },
    v_body_portrait: {
      width: "100%",
    },
    t_logo: {
      fontFamily: "DMSans",
      fontWeight: "500",
      paddingTop: 0,
      fontSize: 50,
      lineHeight: 55,
      marginTop: 29,
      color: colors.white, 
    },
    v_logo_top: { alignItems: "center", display: "none" },
    v_logo_top_2: { alignItems: "center", display: "none" },
  },
  {
    [DEVICE_SIZES.LG]: {
    },
    [DEVICE_SIZES.MD]: {
      v_body_portrait: {
        width: "100%",
        alignItems: "center",
        marginHorizontal: 20,
        height: "80%"
      },
      v_logo_top: { display: "flex", paddingTop: 66 },
      t_logo: { 
        fontSize: 32, 
        lineHeight: 41, 
        marginTop: 15, 
        paddingBottom: 20, 
        fontWeight: "500",
        textAlign: "center"
      },
    },
    [DEVICE_SIZES.SM]: {
      v_body_portrait: {
        width: "100%",
        alignItems: "center",
        marginHorizontal: 20,
        height: "80%"
      },
      v_logo_top: { display: "flex", paddingTop: 15 },
      t_logo: { 
        fontSize: 20, 
        lineHeight: 25, 
        marginTop: 15, 
        paddingBottom: 15, 
        fontWeight: "500",
        textAlign: "center"
      },
    },
    [DEVICE_SIZES.XS]: {
      
      v_logo_top: { display: "flex", paddingTop: 20 },
      t_logo: { 
        fontSize: 20, 
        lineHeight: 25, 
        marginTop: 15, 
        paddingBottom: 20, 
        fontWeight: "500",
        textAlign: "center"
      },
    },
  }
);

export default useStyles;
