// DUCKS pattern
import { createAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@src/ducks/store";
import { AppConfig, ErrorValue } from "../types";

export interface SettingsState {
  updatedAt?: Date,
  loading: boolean;
  config: AppConfig;
  error: ErrorValue;
}

export const initialState: SettingsState = {
  updatedAt: null as any,
  loading: false,
  config: {
    isMaintenance: false,
    subscriptions: [],
  },
  error: {} as ErrorValue,
} as SettingsState;

// Slice
export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    updateMaintenanceRequest: (state) => {
      state.loading = true;
      state.error = {} as ErrorValue;
    },
    updateMaintenanceSuccess: (state, action) => {
      state.updatedAt = new Date()
      state.config = action.payload;
      state.error = {} as ErrorValue;
      state.loading = false;
    },
    updateMaintenanceFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

// Actions
export const settingsActions = {
  updateMaintenanceRequest: createAction(
    `${settingsSlice.name}/updateMaintenanceRequest`,
    (params: AppConfig) => ({
      payload: params,
    })
  ),
  updateMaintenanceSuccess: settingsSlice.actions.updateMaintenanceSuccess,
  updateMaintenanceFailure: settingsSlice.actions.updateMaintenanceFailure,
};

// Selectors
export const selectSettingsUpdatedAt = (state: RootState) => state.settings.updatedAt;
export const selectSettingsLoading = (state: RootState) => state.settings.loading;
export const selectSettingsFailed = (state: RootState) => state.settings.error;
export const selectMaintenance = (state: RootState) => state.settings.config?.isMaintenance ?? false;

// Reducer
export default settingsSlice.reducer;
