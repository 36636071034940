import { useRoute } from "@react-navigation/native";
import { Button01 } from "@src/components/button";
import { TextInput02 } from "@src/components/textinput";
import { useForgotService } from "@src/ducks/hooks";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Text, View } from "react-native";
import ErrorView from "../forgot-password/error";
import useStyles from "./styles.css";

interface ErrorProps {
  newpassword: string;
  confirmpassword: string;
}

const ErrorValue = {
  newpassword: "",
  confirmpassword: "",
};

const ResetPassword = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const token = route.params.token;
  const errorRef = React.useRef<any>(null);
  const { isLoading, onReset } = useForgotService();
  const [error, setError] = useState<ErrorProps>(ErrorValue);
  const [newpassword, setNewpassword] = useState<string>("");
  const [confirmpassword, setConfirmpassword] = useState<string>("");

  const onResetPassword = () => {
    const newError: any = {};
    if (!newpassword) {
      newError.newpassword = "New Password is required.";
    }

    if (!confirmpassword) {
      newError.confirmpassword = "Confirm Password is required.";
    }

    setError(newError);

    if (_.isEmpty(newError)) {
      onReset({
        resetToken: token,
        password: newpassword,
        confirmPassword: confirmpassword,
      });
    }
  };

  useEffect(() => {
    setError({} as ErrorProps);
    errorRef.current?.onClear();
  }, [newpassword]);

  return (
    <View style={styles.v_login}>
      <View style={styles.signInCenter}>
        <Text style={styles.txtNote1}>New Password</Text>
        <Text style={styles.txtNote2}>
        Set the new password for your account
        so you can login and access all features.
        </Text>
      </View>
      <ErrorView ref={errorRef} />
      <View style={styles.v_field}>
        <TextInput02
          hasPassword
          value={newpassword}
          label="New Password"
          error={error.newpassword}
          onChangeText={setNewpassword}
          placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
        />
        <TextInput02
          hasPassword
          value={confirmpassword}
          label="Confirm Password"
          error={error.confirmpassword}
          onChangeText={setConfirmpassword}
          placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
        />
        <Button01
          label="Update Password"
          color={"white"}
          isLoading={isLoading}
          onPress={onResetPassword}
          style={[styles.btn_signin]}
          labelStyle={styles.lbl_signin}
        />
        <View style={styles.v_height} />
      </View>
    </View>
  );
};

export default ResetPassword;
