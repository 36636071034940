import { useEffect, useState } from "react";
import { authActions, selectedAuthAccessToken } from "@src/ducks/slices/auth.slice";
import { useAppDispatch, useAppSelector } from "@src/ducks/ducksHook";
import { ErrorValue } from "@src/ducks/types";
import { API_BASE_URL } from "@env";

export type FetchGetProps = {
    loading: boolean;
    data: any;
    error: ErrorValue;
    refresh: () => void;
  }

export const useFetchGetPDF = (
  routes: string,
  options: any = null,
  initialData = null
) => {
  const dispatch = useAppDispatch();
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState<any>(initialData);
  const [loading, setLoading] = useState<boolean>(true);
  const accessToken = useAppSelector(selectedAuthAccessToken);
  const [error, setError] = useState<ErrorValue>({} as ErrorValue);

  const runRequestCycle = () => {
    async function request() {
      try {
        setLoading(true);
        const response = await fetch(`${API_BASE_URL}${routes}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          signal: controller.signal,
          ...options,
        });

        if (!response.ok) {
          throw response;
        }

        const body = await response.blob();

        const reader = new FileReader();
        reader.onload = function(){ 
          setData(this.result);
          setError({});
          setLoading(false);
          setRefresh(false);
        };
        reader.readAsDataURL(body);
      } catch (err: any) {
        console.log("err", err);

        let message = err.message || err.error || "Something went wrong";
        message = message.replace(/^HttpException:\s*/, "");
        setError({ message });

        if (message.includes("Unauthorized")) {
          dispatch(authActions.logout());
        }
        setLoading(false);
        setRefresh(false);
      }
    }
    const controller = new AbortController();

    accessToken && request();
    return controller;
  };

  useEffect(() => {
    const controller = runRequestCycle();
    return () => {
      controller.abort();
    };
  }, [routes]);

  useEffect(() => {
    if (!refresh) {
      return;
    }
    const controller = runRequestCycle();
    return () => {
      controller.abort();
    };
  }, [refresh]);

  return {
    loading,
    data,
    error,
    refresh: () => setRefresh(true),
  } as any;
};
