import _ from "lodash";
import React, { memo, useRef, useState } from "react";
import {
  Keyboard,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { colors } from "theme";
import useStyles from "./styles.css";
import { InputProps2, InputProps3 } from "./types";

function TextInput04(props: InputProps2 & InputProps3) {
  const styles = useStyles();
  const textRef = useRef<any>();
  const [isFocus, setFocus] = useState(false);
  const { label, onChangeText, error, editable = true } = props;
  const errLabelStyle = error ? { color: colors.red } : {};
  const errBorderStyle: any = error && {
    borderColor: colors.red,
  };

  return (
    <View ref={props.inputRef} style={[styles.marginTop20, props.style]}>
      {!!label && (
        <View style={styles.v_label}>
          <Text style={[styles.label, props.labelStyle, errLabelStyle]}>
            {props.label}
          </Text>

          {_.isEmpty(error) ? null : (
            <Text style={[styles.txtError]}>{error}</Text>
          )}
        </View>
      )}
      <TouchableOpacity
        activeOpacity={1}
        onPress={() => textRef.current.focus()}
        style={[ styles.borderT5, props.borderStyle, errBorderStyle]}
      >
        <TextInput
          ref={textRef}
          style={[
            styles.input, 
            props.inputStyle
          ]}
          autoComplete="off"
          autoCorrect={false}
          value={props.value}
          editable={editable}
          multiline={props.multiline}
          maxLength={props.maxLength}
          onBlur={(e) => {
            props.onBlur && props.onBlur(e);
            setFocus(false);
          }}
          onFocus={() => editable && setFocus(true)}
          keyboardType={props.keyboardType}
          placeholder={isFocus ? "" : props.placeholder}
          placeholderTextColor={colors.grayPrimary}
          onSubmitEditing={Keyboard.dismiss}
          onChangeText={onChangeText}
          aria-disabled={!editable}
        />
        {typeof props.renderIcon === "function" && props.renderIcon()}
      </TouchableOpacity>
    </View>
  );
}

export default memo(TextInput04);
