import { SagaIterator } from "@redux-saga/core";
import { call, delay, put, select, takeEvery } from "redux-saga/effects";

// Types
import { UpgradeSubscription } from "../types";

// API
import { appConfig, createMayaCustomer, generateMayaToken, linkGeneratedMayaTokenToCustomer, paymayaTransaction, updateUser, userInfo, userSubscription } from "@src/utils/api";

// Slice
import { WEB_BASE_URL } from "@env";
// import moment from "moment";
import moment from "@src/utils/moment-timezone-helper";
import { authActions, selectAuthSession, selectedAuthAccessToken } from "../slices/auth.slice";
import { dashboardActions } from "../slices/dashboard.slice";
import { selectSubscriptionTeamID, selectUserDetails, userActions } from "../slices/user.slice";
import { settingsActions } from "../slices/settings.slice";

function* handleUserDetails(): SagaIterator {
  try {
    const session = yield select(selectAuthSession);
    const user = yield call(userInfo, session);
    yield put(userActions.success(user.data));

    const config = yield call(appConfig);
    yield put(dashboardActions.setConfig({
      isMaintenance: config.data.isMaintenance,
      cancelReasons: config?.data?.subscription?.cancelReasons,
    }));
    yield put(settingsActions.updateMaintenanceSuccess(config.data))
  } catch (error: any) {
    console.log("handleUserDetails", error)
    const message = error.message || error.error || "Something went wrong";
    if (message.includes("Unauthorized")) {
      yield put(authActions.logout());
    }
    yield put(userActions.failed({ message }));
    yield delay(1000);
    yield put(userActions.failed({}));
  }
}

function* handleUserSubscription(): SagaIterator {
  try {
    const session = yield select(selectAuthSession);
    const result = yield call(userSubscription, session);
    const subscription = { 
      ...result.data.activeSubscription,
      remainingStorage: result.data.remainingStorage
    };
    yield put(userActions.subscription(subscription));
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    if (message.includes("Unauthorized")) {
      yield put(authActions.logout());
    }
    yield put(userActions.failed({ message }));
    yield delay(1000);
    yield put(userActions.failed({}));
  }
}

function* handleUserUpgradeSubscription(action: {
  type: typeof userActions.upgradeUserSubscription;
  payload: UpgradeSubscription;
}): SagaIterator {
  try {
    const userDetails = yield select(selectUserDetails);
    const teamId = yield select(selectSubscriptionTeamID);
    const accessToken = yield select(selectedAuthAccessToken);
    const customerParams = {
      "contact": {
        "phone": userDetails.phoneNumber === "null" ? "" : userDetails.phoneNumber,
        "email": userDetails.email
      },
      "firstName": userDetails.givenName,
      "middleName": "",
      "lastName": userDetails.lastName,
      "customerSince": moment(userDetails.createdAt).format("YYYY-MM-DD")
    };
    const customer = yield call(createMayaCustomer, customerParams);
    const token = yield call(generateMayaToken, {card: action.payload.card });
    const cardToken = yield call(linkGeneratedMayaTokenToCustomer, {
      customerId: customer.id,
      isDefault: true,
      paymentTokenId: token.paymentTokenId
    });
    const transaction = yield call(paymayaTransaction, customer.id, cardToken.cardTokenId, {
      totalAmount: {
        amount: action.payload.cost,
        currency: "PHP"
      },
      redirectUrl: {
        success: `${WEB_BASE_URL}/payment-success`,
        failure: `${WEB_BASE_URL}/payment-failed`,
      },
      requestReferenceNumber: action.payload.id,
      metadata: {
        pf: {
          smi: userDetails.id,
          smn: `${userDetails.givenName} ${userDetails.lastName}`,
          mci: userDetails.office,
          mpc: "PHP",
          mco: "PHL",
        },
        user: {
          id: userDetails.id,
          email: userDetails.email,
          firstName: userDetails.givenName,
          lastName: userDetails.lastName,
          mayaCustomerId: customer.id,
          mayaCardtokenId: cardToken.cardTokenId,
        },
        subMerchantRequestReferenceNumber: teamId,
      },
    });
    const userResult = yield call(updateUser, {
      id: userDetails.id,
      mayaCustomerId: customer.id,
      mayaCardtokenId: cardToken.cardTokenId,
    }, accessToken);

    window.location.href = transaction.verificationUrl;
    yield put(userActions.upgradeUserSubscriptionSuccess(transaction));
    yield put(userActions.updateUserSuccess(userResult.data));
  } catch (error: any) {
    if(error.parameters && typeof error.parameters === "object"){
      yield put(userActions.upgradeUserSubscriptionFailed({ message: error.parameters[0].description }));
      yield delay(1000);
      yield put(userActions.upgradeUserSubscriptionFailed({}));
    }else{
      const message = error.message || error.error || "Something went wrong";
      if (message.includes("Unauthorized")) {
        yield put(authActions.logout());
      }
      yield put(userActions.upgradeUserSubscriptionFailed({ message }));
      yield delay(1000);
      yield put(userActions.upgradeUserSubscriptionFailed({}));
    }
  }
}

// Watcher Saga
function* userWatcherSaga(): SagaIterator {
  yield takeEvery(userActions.fetchUserDetails.type, handleUserDetails);
  yield takeEvery(userActions.fetchUserSubscription.type, handleUserSubscription);
  yield takeEvery(userActions.upgradeUserSubscription.type, handleUserUpgradeSubscription);
}

export default userWatcherSaga;
