import React, { useContext } from "react";
import { selectedDrawerStatus } from "@src/ducks/slices/dashboard.slice";
import { TouchableOpacity, View, Image, Pressable } from "react-native";
import { useDashboardService } from "@src/ducks/hooks";
import { useAppSelector } from "@src/ducks/ducksHook";
import Icon from "@expo/vector-icons/Feather";
import { DrawerContext } from "../drawer-provider";
import useStyles from "./styles.css";
import images from "@assets/images";
import colors from "@assets/colors";

const MobileHeader = () => {
  const styles = useStyles();
  const { onDrawerEnable } = useDashboardService();
  const drawerEnable = useAppSelector(selectedDrawerStatus);
  const { onActiveTab } = useContext(DrawerContext);

  return (
    <View style={styles.drawer_mobile_container}>
      <Pressable android_ripple={null} android_disableSound={true} onPress={() => onActiveTab("Dashboard")}>
        <Image
          source={images.signsecurev2_black}
          style={styles.drawer_mobile_logo}
          resizeMode="contain"
        />
      </Pressable>
      <TouchableOpacity onPress={() => onDrawerEnable(!drawerEnable)}>
        <Icon name="menu" size={30} color={colors.white} />
      </TouchableOpacity>
    </View>  
  );
};

export default MobileHeader;
