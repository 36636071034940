export const add = (a: number, b: number) => {
  return Number(a) + Number(b);
};

export const substract = (a: number, b: number) => {
  return Number(a) - Number(b);
};

export const multiply = (a: number, b: number) => {
  return Number(a) * Number(b);
};

export const divide = (a: number, b: number) => {
  return Number(a) / Number(b);
};
