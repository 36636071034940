import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { colors } from 'theme';

const useStyles = CreateResponsiveStyle(
  {
    container: {
      height: 90,
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: 25,
      paddingRight: 50,
      backgroundColor: colors.blue5,
      justifyContent: 'space-between',
    },
    logo_signsecure: { width: 150, height: 60, tintColor: colors.white },
    btn_style: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    t_back: { fontFamily: 'DMSans-Bold', color: 'white', fontSize: 18, marginLeft: 10 },
  },
  {
    [DEVICE_SIZES.LG]: {},
    [DEVICE_SIZES.MD]: {},
    [DEVICE_SIZES.SM]: {
      logo_signsecure: { width: 140, height: 60 },
    },
    [DEVICE_SIZES.XS]: {
      logo_signsecure: { width: 140, height: 60 },
    },
  }
);

export default useStyles;
