import * as ScreenOrientation from "expo-screen-orientation";
import { useEffect, useState } from "react";

const useOrientationHelper = () => {
  const [orientation, setOrientation] = useState<any>(null);

  useEffect(() => {
    const checkOrientation = async () => {
      const initialOrientation = await ScreenOrientation.getOrientationAsync();
      setOrientation(initialOrientation);
    };

    const handleOrientationChange = ({orientationInfo}:any) => {
      setOrientation(orientationInfo.orientation);
    };

    checkOrientation();

    ScreenOrientation.addOrientationChangeListener(handleOrientationChange);

    return () => {
      ScreenOrientation.removeOrientationChangeListeners();
    };
  }, []);

  return [3, 4].includes(orientation);
};

export default useOrientationHelper;
