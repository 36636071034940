import { useNavigation } from "@react-navigation/native";
import { Button01 } from "@src/components/button";
import { NavigationScreenProps } from "@src/navigators/NavigatorTypes";
import React from "react";
import { Text, View } from "react-native";
import useStyles from "./styles.css";

const PasswordChange = () => {
  const styles = useStyles();
  const navigation = useNavigation<NavigationScreenProps<"Home">>();

  return (
    <View style={[styles.v_login, { justifyContent: "center" }]}>
      <View style={[styles.signInCenter, { marginTop: 0 }]}>
        <Text style={styles.txtNote1}>Password changed!</Text>
        <Text style={styles.txtNote2}>
          Your password has been successfully changed. You may now log in using
          your new credentials.
        </Text>
      </View>
      <Button01
        label="Back to log in"
        style={styles.btn_signin}
        labelStyle={styles.lbl_signin}
        onPress={() => navigation.navigate("Signin")}
      />
    </View>
  );
};

export default PasswordChange;
