import React from "react";
import { View } from "react-native";
import { DrawerContext } from "../../drawer-provider";
import CreateButton from "./create-button";
import StepsHeader from "./create-document-steps";
import SearchHeader from "./search-header";
import useStyles from "./styles.css";
import TitleSection from "./title-section";

const IgnoreSearchBar = ["My Account", "Advance Settings", "Settings"];
const IgnoreSearchBarSecond = ["signature-upload"];

const WebHeader = () => {
  const styles = useStyles();
  const { activeTab, activeSecondScreen } = React.useContext(DrawerContext);

  return (
    <View style={styles.v_header_items}>
      <TitleSection />
      {IgnoreSearchBar.includes(activeTab) || IgnoreSearchBarSecond.includes(activeSecondScreen) ? 
        null :
        <>
          <SearchHeader />
          <CreateButton />
        </> }
    </View>
  );
};

export default WebHeader;
