import { Asset } from "expo-asset";
import { ImageRequireSource } from "react-native";

interface imageType {
  // Assets
  favicon: ImageRequireSource;

  // AUTH
  bg_signin: ImageRequireSource;
  bg_signup: ImageRequireSource;
  google: ImageRequireSource;
  signsecurev2: ImageRequireSource;
  signsecurev2_black: ImageRequireSource;
  check: ImageRequireSource;
  error: ImageRequireSource;
  scan_frame: ImageRequireSource;
  bg_forgot: ImageRequireSource;
  ic_forgot_success: ImageRequireSource;
  blueLogoSignsecure: ImageRequireSource;
  productUnawa: ImageRequireSource;

  // SIGNATURE
  ic_check: ImageRequireSource;
  ic_delete: ImageRequireSource;
  ic_upload: ImageRequireSource;
  ic_resize: ImageRequireSource;
  "quick-signing": ImageRequireSource;


  bg_mask: ImageRequireSource;
  placeholder_profile: ImageRequireSource;
  document_icon: ImageRequireSource;
  icon_download: ImageRequireSource;
  cloud_download: ImageRequireSource;
  icon_share: ImageRequireSource;
  icon_close: ImageRequireSource;
  icon_pen: ImageRequireSource;
  icon_gallery: ImageRequireSource;
  icon_upload: ImageRequireSource;
  icon_text: ImageRequireSource;
  icon_reload: ImageRequireSource;
  icon_upload_success: ImageRequireSource;
  ic_logo: ImageRequireSource;
  "camera-bg": ImageRequireSource;

  // LIVEREQUEST
  ic_document: ImageRequireSource;
  ic_calendar: ImageRequireSource;
  ic_eye: ImageRequireSource;
  ic_approved: ImageRequireSource;
  ic_reject: ImageRequireSource;
  ic_docs: ImageRequireSource;

  // HEADER
  ic_back: ImageRequireSource;
  signsecurev2LowOpacity: ImageRequireSource;

  // CREATE DOCUMENT
  upload_docs: ImageRequireSource;
  jpg_icon: ImageRequireSource;
  pdf_icon: ImageRequireSource;
  docx_icon: ImageRequireSource;
  png_icon: ImageRequireSource;

  //Face ID
  face_box: ImageRequireSource;

  //edit profile
  ic_edit: ImageRequireSource;
  ic_save: ImageRequireSource;

  //dashboard 
  banner_image: ImageRequireSource;
  banner_image_mobile: ImageRequireSource;
  bg_banner_new: ImageRequireSource;
  bg_banner_two: ImageRequireSource;
  bg_banner_mobile: ImageRequireSource;

}

const images: imageType = {
  // ASSETS
  favicon: require("../Blue_logo_signsecure.png"),
  banner_image: require("../images/banner.png"),
  blueLogoSignsecure: require("../images/Blue_logo_signsecure.png"),
  banner_image_mobile: require("../images/bg_mobile_banner.png"),

  // AUTH FOLDER
  bg_signin: require("./auth/signin_bg.png"),
  bg_signup: require("./auth/bg_signup.webp"),
  productUnawa: require("./auth/product_unawa.png"),
  google: require("./auth/google.png"),
  signsecurev2: require("./auth/signsecurev2.png"),
  signsecurev2_black: require("./auth/signsecure-black-logo.png"),
  bg_mask: require("./auth/bg_mask.png"),
  check: require("./auth/check.png"),
  error: require("./auth/error.png"),
  scan_frame: require("./auth/scan_frame.png"),
  bg_forgot: require("./auth/bg_forgot.webp"),
  ic_forgot_success: require("./auth/ic_forgot_success.png"),

  // SIGNATURE
  ic_check: require("./signature/ic_check.png"),
  ic_delete: require("./signature/ic_delete.png"),
  ic_upload: require("./signature/ic_upload.png"),
  ic_resize: require("./signature/ic_resize.png"),
  "quick-signing": require("./signature/quick-signing.png"),


  placeholder_profile: require("./placeholder_profile.png"),
  document_icon: require("./document_icon.png"),
  icon_download: require("./icon_download.png"),
  cloud_download: require("./cloud-download.png"),
  icon_share: require("./icon_share.png"),
  icon_close: require("./icon_close.png"),
  icon_pen: require("./icon_pen.png"),
  icon_upload: require("./icon_upload.png"),
  icon_gallery: require("./icon_gallery.png"),
  icon_text: require("./icon_text.png"),
  icon_reload: require("./icon_reload.png"),
  icon_upload_success: require("./icon_upload_success.png"),
  ic_logo: require("./ic_logo.png"),
  "camera-bg": require("./camera-bg.png"),

  // LIVEREQUEST
  ic_calendar: require("./liverequest/ic_calendar.png"),
  ic_eye: require("./liverequest/ic_eye.png"),
  ic_approved: require("./liverequest/ic_approved.png"),
  ic_reject: require("./liverequest/ic_reject.png"),
  ic_docs: require("./liverequest/ic_docs.png"),
  
  // HEADER
  ic_back: require("./header/ic_back.png"),
  signsecurev2LowOpacity:require("./auth/signsecurev2_lowopacity.png"),

  // CREATE DOCUMENT
  upload_docs: require("./create-document/upload_docs.png"),
  ic_document: require("./create-document/ic_document.png"),
  jpg_icon: require("./create-document/Jpg_icon.png"),
  pdf_icon: require("./create-document/pdf_icon.png"),
  docx_icon: require("./create-document/docx_icon.png"),
  png_icon: require("./create-document/png_icon.png"),


  //face-id
  face_box: require("./auth/face_box@3x.png"),

  //edit profile
  ic_edit: require("./ic_edit.png"),
  ic_save: require("./ic_save.png"),

  // dahsboard banner
  bg_banner_new: require("./bg_banner.png"),
  bg_banner_two: require("./bg_banner_2.png"),
  bg_banner_mobile: require("./bg_banner_mobile.png"),

  
};

// image preloading
export const imageAssets = Object.keys(images).map((key) =>
  Asset.fromModule(images[key as keyof imageType]).downloadAsync()
);
export default images;
