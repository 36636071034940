import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import TypographyStyle from "@assets/typography";
import { StyleSheet } from "react-native";
import colors from "@assets/colors";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      backgroundColor: colors.white,
      shadowColor: colors.black1,
      shadowOffset: { width: 0, height: 1 },
      shadowOpacity: 0.08,
      shadowRadius: 1,
      borderRadius: 8,
      
    },
    header_title_background: {
      backgroundColor: "#F1F1F1",
      height: 74,
      width: "100%",
      justifyContent: "center"
    },
    header_title_top: StyleSheet.flatten([
      TypographyStyle.heading1Bold,
      { marginLeft: 40, 
        color:"#A3A3A3", 
        fontSize:16, 
        marginTop:0,
        },
    ]),
    v_invite_link_wrapper: {
      flexDirection: "row", 
      justifyContent: "flex-start", 
      alignItems: "center", 
      marginTop:40
    },
    signInCenter: { 
      alignItems: "center",
      height: 1000
    },
    divider:{
      marginTop: 24
    },
    midDivider:{
      marginRight: 24
    },
    v_account: {
      alignItems: "center", 
      marginTop: 10,
      width: 800
    },
    t_init_message: {
      fontFamily: "DMSans-Bold", 
      marginLeft: 100,
      marginRight: 100,
      marginTop: 30,
      fontSize: 24, 
      lineHeight: 28, 
      color: colors.colorPrimary, 
      textAlign: "center",
    },
    btn_cancel: { width: 156, height: 47, marginTop: 46, paddingHorizontal: 0,
      backgroundColor: colors.transparent, borderWidth: 1, borderColor: colors.colorPrimary },
    label_cancel: { fontFamily: "DMSans-Bold", color: colors.colorPrimary },
    btn_proceed: { width: 156, height: 47, marginTop: 46,
      paddingHorizontal: 0, backgroundColor: colors.colorPrimary },
    label_proceed: { fontFamily: "DMSans-Bold" },
  }
);

export default useStyles;
