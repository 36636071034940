// src/components/modals/ErrorModal.tsx
import React from 'react';
import { Modal, View, Text, TouchableOpacity } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '@src/ducks/slices/auth.slice';
import colors from "@assets/colors";

const ErrorModal = () => {
  const dispatch = useDispatch();
  const error = useSelector((state:any) => state.auth.error.message);
  const isVisible = !!error;

  const handleClose = () => {
    dispatch(authActions.resetLoading()); // Reset error state
  };

  return (
    <Modal
      transparent
      visible={isVisible}
      animationType="none"
      onRequestClose={handleClose}
    >
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <View style={{ justifyContent: 'center', alignItems: 'center', width: 534, height: 326, 
                       backgroundColor:colors.white, borderRadius: 10,      shadowColor: "gray",
                       shadowOffset: { width: 6, height: 6 },
                       shadowOpacity: 0.6,
                       shadowRadius: 10,}}>
          <Text style={{ fontFamily: "DMSans-Bold", fontSize: 20, marginTop: 0,
                        lineHeight: 28, color: colors.blue, textAlign: "center" }}>
                  {error}
          </Text>
            <TouchableOpacity style={{  justifyContent: 'center', alignItems: 'center', backgroundColor: colors.blue,  width: "33%",
                height: 47, borderRadius: 10, top: 20}} onPress={handleClose}>
              <Text style={{fontFamily: "DMSans-Bold", color: colors.white, textAlign: "center"}}>Close</Text>
            </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default ErrorModal;