import images from "@assets/images";
import useOrientationHelper from "@src/utils/orientation-helper";
import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import { ImageBackground } from "react-native";
import useStyles from "./styles.css";

const Background = () => {
  const styles = useStyles();
  const isLandScape = useOrientationHelper();

  return (
    <>
      <LinearGradient
        colors={["#253A60", "#253A60", "#4E70BB", "#4E70BB"]}
        style={[isLandScape ? styles.linearStyle : styles.linearStyle_portrait]}
      />
      <ImageBackground
        resizeMode="stretch"
        source={images.bg_signin}
        style={[styles.i_bg]}
      />
    </>
  );
};

export default Background;
