import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    drawer_item_container: { flex: 1 },
    v_body: { flexGrow: 1 }
  },
  {
    [DEVICE_SIZES.XL]: {
      v_body: { minHeight: 815 },
    },
    [DEVICE_SIZES.LG]: {
      v_body: { minHeight: 697 },
    },
    [DEVICE_SIZES.XS]: {
      v_body: { flexGrow: 1 }
    },
  }
);

export default useStyles;
