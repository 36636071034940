import TypographyStyle from "@assets/typography";
import { isIOSafari } from "@src/utils/validation-helper";
import { StyleSheet } from "react-native";
import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import { colors } from "theme";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: "60%",
      height: "15%",
      borderRadius: 5,
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: colors.white,
    },
    label: TypographyStyle.fieldsTitle,
    v_label: { flexDirection: "row", justifyContent: "space-between" },
    marginTop20: { marginTop: 29, paddingHorizontal: isIOSafari() ? 2 : 0 },
    border: {
      marginTop: 10,
      height: 54,
      borderWidth: 1,
      borderRadius: 8,
      alignItems: "center",
      justifyContent: "center", 
      borderColor: colors.border2,
    },
    border_t6: {
      marginTop: 10,
      height: 42,
      borderWidth: 1,
      borderRadius: 8,
      alignItems: "center",
      justifyContent: "center", 
      borderColor: colors.black3,
    },
    t3_border: {
      marginTop: 10,
      minHeight: 38,
      borderWidth: 1,
      borderRadius: 8,
      flexDirection: "row",
      alignItems: "center",
      paddingHorizontal: 16,
      justifyContent: "space-between",
      borderColor: colors.black3,
    },
    t4_border: {
      marginTop: 10,
      minHeight: 51,
      borderWidth: 1,
      borderRadius: 8,
      alignItems: "center",
      justifyContent: "center",
      borderColor: colors.border2,
    },
    errorStyle: {
      borderWidth: 2,
      borderColor: colors.red,
    },
    input: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        flex: 1,
        width: "100%",
        height: "100%",
        borderRadius: 8,
        color: colors.black3,
        paddingHorizontal: 16,
      },
    ]),
    t_input: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        borderRadius: 8,
      },
    ]),
    inputPadding: { paddingTop: 12 },
    txtError: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      {
        fontSize: 13,
        color: colors.red,
        fontStyle: "italic",
        fontFamily: "DMSans-Bold",
        right:5
      },
    ]),
    textError: {
      right: 10,
      fontSize: 10,
      color: colors.red,
      position: "absolute",
      alignSelf: "center",
      fontFamily: "DMSans",
    },
    btn_icon: { position: "absolute", width: 24, height: 24, right: 10 },
    btn_icon_refresh: { position: "absolute", width: 24, height: 24, right: 100 },
    btn_icon_calendar: { position: "absolute", width: 24, height: 24, left: "90%"},
    refreshText: {
      left: 10,
      fontSize: 14,
      fontFamily:"DMSans",
    }
  },
  {
    [DEVICE_SIZES.LG]: {
      marginTop20: { marginTop: 25 },
      border: { height: 47, marginTop: 9, borderRadius: 6 },
      label: { fontSize: 10.5, lineHeight: 10.5 },
      input: { fontSize: 10.5, lineHeight: 10.5, borderRadius: 6 },
      t_input: { fontSize: 10.5, lineHeight: 10.5 },
      txtError: { fontSize: 10.5, lineHeight: 10.5 },
      textError: { fontSize: 10.5, lineHeight: 13.13 },

      t3_border: {
        marginTop: 8,
        minHeight: 36,
        borderWidth: 1,
        borderRadius: 6,
      },
      border_t6: {
        marginTop: 8,
        height: 36,
        borderWidth: 1,
        borderRadius: 6,
      },
    },
    [DEVICE_SIZES.MD]: {
      input: { fontSize: 13, lineHeight: 14, borderRadius: 6 },
      marginTop20: { marginTop: 32}
    },
    [DEVICE_SIZES.SM]: {
      input: { fontSize: 12, borderRadius: 6 },
      marginTop20: { marginTop: 25},
      border: { height: 45, marginTop: 9, borderRadius: 6 },
    },
    [DEVICE_SIZES.XS]: {
      input: { fontSize: 12, borderRadius: 6 },
      marginTop20: { marginTop: 25},
      border: { height: 45, marginTop: 9, borderRadius: 6 },
    },
  }
);

export default useStyles;
