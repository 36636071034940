import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { colors } from "theme";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: "50%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.white,
    },
    container_landscape: {
      width: "50%",
      height: "100%",
      alignSelf: "flex-end",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.white,
    },
    scrollview: { flex: 1, width: "100%" },
    v_center_blur: {
      flex: 1,
      zIndex: 0,
      width: "100%",
      height: "100%",
      position: "absolute",
      backgroundColor: colors.transparent,
    },
    v_center: {
      zIndex: 2,
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    v_center_landscape: {
      zIndex: 2,
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    v_mask_container: {
      width: 600, 
      height: 790,
      alignItems: "center",
      justifyContent: "center",
    },
    v_mask: {
      zIndex: 1,
      width: "100%",
      height: "100%",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
    },
    t_face_recog: {
      fontSize: 33,
      lineHeight: 28,
      textAlign: "center",
      color: colors.black2,
      fontFamily: "DMSans-Bold",
    },
    t_face_recog_landscape: {
      marginTop: 34,
      fontSize: 22,
      lineHeight: 29,
    },
    t_fr_sub: {
      fontFamily: "DMSans",
      fontSize: 18,
      lineHeight: 32,
      marginTop: 20,
      textAlign: "center",
      color: colors.black2,
    },
    v_frame_center: {
      width: 450,
      height: 550,
      marginTop: 33,
      marginBottom: 52,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "transparent"
    },
    v_frame_center_landscape: {
      paddingVertical: 30,
    },
    i_frame: {
      width: 335,
      height: 440,
      justifyContent: "center"
    },
    i_frame_landscape: {
      width: 320,
      height: 350,
    },
    btn_style: {
      minWidth: 300,
      marginTop: 30,
      backgroundColor: colors.colorPrimary
    },
    btn_style_lanscape: {
      marginTop: 15,
    },
    termsStyle: { zIndex: 2, position: "relative", marginTop: 20, paddingBottom: 50},


    // PROGRESS BAR
    v_progress: { 
      width: "100%",
      alignItems: "center", 
      justifyContent: "center"
    },
    t_percentage: {
      fontFamily: "DMSans",
      fontSize: 18,
      lineHeight: 32,
      marginTop: 21,
      color: colors.black3,
    },
    progress: {
      width: "50%",
      height: 12,
      marginTop: 13,
      borderRadius: 50,
      backgroundColor: colors.white,
      borderWidth: 1,
      borderColor: colors.black,
    },
    progress_active: {
      width: 20,
      height: "100%",
      borderRadius: 50,
      backgroundColor: colors.blue12,
    },

    // CAMERA
    v_camera_view: { 
      zIndex: 0, position: "absolute",
      width: "100%", height: "100%", 
      justifyContent: "center", alignItems:"center",
      backgroundColor: "black", borderRadius: 8
    },

    // ARROW BACK BUTTON
    v_arrow_back_button: {
      zIndex: 3,
      height: 74,
      position: "absolute",
      left: 35, top: 20,
      justifyContent: "center", 
      alignItems: "flex-start",
      backgroundColor: colors.transparent,
    },
    btn_arrow_back: {
      flexDirection: "row",
      justifyContent: "flex-start", 
      alignItems: "center",
    },
    header_title_top: { 
      fontFamily: "DMSans-Bold",
      marginLeft: 40, 
      color: colors.grayPrimary, 
      fontSize: 16,
      lineHeight: 24, 
    },
  },
  {
    [DEVICE_SIZES.LG]: {
      v_mask_container: {
        width: 530, 
        height: 750, 
      },
      t_face_recog: {
        fontSize: 29,
        lineHeight: 26,
      },
      t_fr_sub: {
        fontSize: 15,
        lineHeight: 28,
        marginTop: 12,
      },
      v_frame_center: {
        width: 380,
        height: 450,
        marginTop: 25,
        marginBottom: 27,
      },
      btn_style: {
        marginTop: 20,
      },
      t_percentage: {
        fontSize: 14,
        lineHeight: 27,
        marginTop: 16,
      },

      // ARROW BACK BUTTON
      v_arrow_back_button: {
        height: 50,
        left: 35, top: 20,
      },
      header_title_top: { 
        marginLeft: 25, 
        fontSize: 12,
        lineHeight: 18, 
      },
    },
    [DEVICE_SIZES.MD]: {
      container: {
        width: "100%",
      },
      v_camera_view: { borderRadius: 0 },
      v_center: {
        marginTop: 35,
        justifyContent: "flex-start",
        width: "100%", height: "100%", 
      },
      v_mask_container: {
        width: 600, 
        height: 790,
        backgroundColor: "white",
        shadowColor: "gray",
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.2,
        shadowRadius: 10,
      },
      t_face_recog: {
        fontSize: 24,
        lineHeight: 32,
      },
      t_fr_sub: {
        fontSize: 14,
        lineHeight: 32,
        marginTop: 0,
      },
      v_frame_center: {
        width: 335,
        height: 440,
        marginTop: 90,
        marginBottom: 35,
      },
      v_progress: {
        flexDirection: "column-reverse"
      },
      t_percentage: {
        fontFamily: "DMSans-Bold",
        fontSize: 24,
        color: colors.white,
        marginTop: "4%",
      },

      v_arrow_back_button: { display: "none"}
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      container: {
        width: "100%",
      },
      v_center: { 
        width: "100%", 
        height: "100%", 
        justifyContent: "flex-start",
        
      },
      v_mask_container: {
        width: "100%", 
        height: 651,
      },
      t_face_recog: {
        fontSize: 24,
        lineHeight: 32,
        marginTop: 36 
      },
      t_fr_sub: {
        fontSize: 14,
        lineHeight: 32,
        marginTop: 0,
      },
      v_frame_center: {
        width: "100%",
        height: 390,
        marginTop: 50,
        marginBottom: 20,
      },
      i_frame: {
        width: 298,
        height: 390,
      },
      progress: {
        width: "75%",
      },
      v_progress: {
        flexDirection: "column-reverse"
      },
      t_percentage: {
        fontFamily: "DMSans",
        fontSize: 14,
        lineHeight: 32,
        color: colors.white,
        marginTop: "4%",
      },
      
      v_camera_view: { borderRadius: 0 },
      v_arrow_back_button: { display: "none"}
    },
  }
);

export default useStyles;
