import React, { useState, useCallback, createContext, Dispatch, SetStateAction } from "react";
import PopupMenu from ".";
import _ from "lodash";

type MeasureValue = {
  x: number;
  y: number;
  width: number;
  height: number;
  index?: number;
}

interface ContextValue {
  selected: any;
  measure: MeasureValue;
  onSetOptions: (value: any) => void;
  onSetMeasure: (value: MeasureValue) => void;
  setSelected: Dispatch<SetStateAction<any>>
}

export const PopupMenuContext = createContext<ContextValue>({} as ContextValue);

interface ProviderProps {
  children: React.ReactElement;
}

const PopupMenuProvider = ({ children }: ProviderProps) => {
  const [options, setOptions] = useState<any>([]);
  const [selected, setSelected] = useState<any>(null);
  const [measure, setMeasure] = useState<MeasureValue>({} as MeasureValue);

  const onSetMeasure = useCallback(
    (value: MeasureValue) => {
      setMeasure(value);
    },[measure]
  );

  const onSetOptions = useCallback(
    (value: any) => {
      setOptions(value);
    },[options]
  );

  const value = {
    measure,
    selected,
    setSelected,
    onSetMeasure,
    onSetOptions,
  };

  return (
    <PopupMenuContext.Provider value={value}>
      {children}
      <PopupMenu 
        measures={measure}
        value={selected}
        options={options}
        onSelected={setSelected} 
        visible={!_.isEmpty(measure)}
        onClose={() => setMeasure({} as MeasureValue)}/>
    </PopupMenuContext.Provider>
  );
};

export default React.memo(PopupMenuProvider);
